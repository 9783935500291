import React, { useEffect } from 'react';
import { useAppSelector } from '../hooks/store';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { Table, Button, TablePaginationConfig } from 'antd';
import { DONORS_MAX_RESULTS } from 'src/slices/donor';
import { getDonorsForContributor } from 'src/slices/donor';
import { useCaptchaDispatch } from '../hooks/useCaptchaDispatch';
import { Donor } from '../slices/donor';

const useStyles = createUseStyles({
  table: {
    maxWidth: '100%',
    paddingLeft: '1.25vw',
    paddingRight: '1.25vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  name: {
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '12.5vw',
  },
});

export const DonorsToQualify: React.VFC = () => {
  const classes = useStyles();
  const captchaDispatch = useCaptchaDispatch();
  const history = useHistory();
  const { donors, searchResult, offset } = useAppSelector(
    ({ donor: { donors, searchResult, offset } }) => ({
      donors,
      searchResult,
      offset,
    }),
  );
  const args = {
    limit: DONORS_MAX_RESULTS,
    offset: 0,
    statusFilter: 'assigned',
  };
  const onEditDonor = (donorId: string) => {
    history.push(`/donor/${donorId}/edit`);
  };
  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: '_id',
      render: function renderName(name: string, record: Donor) {
        return (
          <div
            className={classes.name}
            onClick={() => history.push(`/donor/${record._id}`)}
          >
            {name}
          </div>
        );
      },
    },
    {
      title: 'Année de création',
      dataIndex: 'creationYear',
      key: 'creationYear',
    },
    {
      title: 'Adresse',
      dataIndex: 'headquartersAddress',
      key: 'headquartesAdress',
    },
    {
      title: '',
      key: 'actions',
      width: '3%',
      fixed: 'right' as const,
      render: function renderActions(record: Donor) {
        return (
          <Button
            className="ant-dropdown-link"
            onClick={() => onEditDonor && onEditDonor(record._id)}
          >
            Modifier
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    captchaDispatch(getDonorsForContributor, getDonorsForContributor(args));
  }, []);

  const fetchDonors = (page: number) => {
    captchaDispatch(
      getDonorsForContributor,
      getDonorsForContributor({
        offset: (page - 1) * DONORS_MAX_RESULTS,
        statusFilter: 'assigned',
        limit: DONORS_MAX_RESULTS,
      }),
    );
  };

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 0;
    fetchDonors(newSearchPage);
  };

  return (
    <>
      <Table
        className={classes.table}
        dataSource={searchResult?.resultsIds?.map(
          (donnorsId) => donors.byId[donnorsId],
        )}
        columns={columns}
        pagination={{
          total: searchResult?.resultsCount,
          current: offset / DONORS_MAX_RESULTS + 1,
          pageSize: DONORS_MAX_RESULTS,
          showSizeChanger: false,
        }}
        onChange={onTableChange}
        rowKey="_id"
      />
    </>
  );
};
