import React, { useEffect, useMemo } from 'react';
import { Typography } from 'antd';
import { createUseStyles } from 'react-jss';
import { Droppable } from 'react-beautiful-dnd';
import {
  CheckCircleOutlined,
  DollarOutlined,
  FileSearchOutlined,
  SendOutlined,
} from '@ant-design/icons';

import { getDealFlowCards } from 'src/slices/dealFlowCard';
import { theme } from 'src/constants/theme';
import { DealFlowColumn } from 'src/slices/dealFlowColumn';
import { useAppSelector, useAppDispatch } from 'src/hooks/store';
import { KanbanCard } from './KanbanCard';
import { useParams } from 'react-router-dom';

interface Props {
  columnId: string;
  setColumnPosition: (columnId: string, position: number) => void;
}

const useStyles = createUseStyles({
  column: {
    marginLeft: '2.76%',
    height: '100%',
    width: '25%',
  },
  firstColumn: {
    height: '100%',
    width: '25%',
  },
  cardsContainer: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    borderBottomLeftRadius: '0.3125vw',
    borderBottomRightRadius: '0.3125vw',
    padding: '0px 7.36% 11.5%',
    width: '100%',
    overflowY: 'auto',
  },
  cardsList: {
    backgroundColor: theme.palette.common.white,
  },
  columnTitle: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    padding: '4.6% 3.68% 15.65% 9.2%',
    fontWeight: 'bold',
    display: 'flex',
    borderTopLeftRadius: '0.3125vw',
    borderTopRightRadius: '0.3125vw',
    fontSize: '0.9375vw',
  },
  addCardButtonContainer: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    borderColor: 'transparent',
    borderBottomLeftRadius: '0.3125vw',
    borderBottomRightRadius: '0.3125vw',
    marginTop: '-0.3125vw',
    '& .ant-btn': {
      height: 'auto',
      padding: '2.76% 0.9375vw 5.52%',
      boxShadow: 'none !important',
      display: 'flex',
      '& .anticon': {
        lineHeight: '0px !important',
      },
    },
  },
  addCardButton: {
    width: '100%',
    borderColor: 'transparent',
    borderBottomLeftRadius: '0.3125vw',
    borderBottomRightRadius: '0.3125vw',
    '& .ant-btn:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,
      borderColor: 'transparent',
    },
  },
  addCardText: {
    backgroundColor: 'inherit',
    color: '#5050f9',
    margin: 'auto auto auto 0px !important',
    fontSize: '0.78125vw',
  },
  addCardInputContainer: {},
  addCardInput: {
    marginTop: '5.25%',
  },
  autocompleteDropdown: {
    paddingTop: '3.75%',
  },
  columnIcon: {
    fontSize: '1.875vw',
    color: '#00BD9C',
    marginLeft: 'auto',
  },
});

export const KanbanColumn: React.VFC<Props> = ({
  columnId,
  setColumnPosition,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { columns, user } = useAppSelector(
    ({ dealFlowColumn: { columns }, auth: { user } }) => ({
      columns,
      user,
    }),
  );
  const { projectId } = useParams<{ projectId: string }>();
  const { callForTenderId } = useParams<{ callForTenderId: string }>();

  const column = useMemo<DealFlowColumn>(() => {
    return columns && columns[columnId];
  }, [columns[columnId]]);

  useEffect(() => {
    dispatch(getDealFlowCards(columnId));
  }, [column]);

  useEffect(() => {
    if (column && column.position) {
      setColumnPosition(columnId, column.position);
    }
    if (column && column.position === 0) {
      setColumnPosition(columnId, 0);
    }
  }, [column]);

  const columnIcon = [
    <FileSearchOutlined
      key={0}
      className={classes.columnIcon}
      style={{ marginRight: '5.28%' }}
    />,
    <CheckCircleOutlined
      key={1}
      className={classes.columnIcon}
      style={{ marginRight: '2.11%' }}
    />,
    <SendOutlined key={2} className={classes.columnIcon} />,
    <DollarOutlined
      key={3}
      className={classes.columnIcon}
      style={{ marginRight: '1.056%' }}
    />,
  ];

  if (!column) return null;

  function chooseName(name: string) {
    if (user?.ngoId) {
      switch (name) {
        case 'A étudier':
          return 'À valider';
        case 'Validés':
          return 'Validé';
        case 'Dossier envoyé':
          return 'Projet transmis';
        case 'Subventions obtenues':
          return 'Soutien accordé';
        default:
          return name;
      }
    } else {
      switch (name) {
        case 'Dossier envoyé':
          return 'Contact pris';
        case 'A étudier':
          return 'A étudier';
        case 'Validés':
          return 'Validé';
        case 'Subventions obtenues':
          return 'Partenariat en cours';
        default:
          return name;
      }
    }
  }

  return (
    <div
      className={column.position === 0 ? classes.firstColumn : classes.column}
      key={column.position}
    >
      <div className={classes.columnTitle}>
        <Typography style={{ color: '#00BD9C' }}>
          {chooseName(column.name)}
        </Typography>
        {columnIcon[column.position]}
      </div>
      <Droppable droppableId={column._id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver
                ? theme.palette.blue[1]
                : theme.palette.common.white,
            }}
            className={classes.cardsContainer}
            {...provided.droppableProps}
          >
            <div className={classes.cardsList}>
              {column.cards?.map((cardId: string, cardIndex: number) => (
                <KanbanCard
                  key={cardId}
                  cardId={cardId}
                  index={cardIndex}
                  columnId={column._id}
                  projectId={projectId ? projectId : callForTenderId}
                />
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};
