import { notification, TablePaginationConfig, Typography, Select } from 'antd';
import { min } from 'lodash';
import { parse, stringify } from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation, useHistory, useParams } from 'react-router';

import { useSortedClassification } from 'src/hooks/useSortedClassification';
import {
  DatabaseSearchBar,
  SearchFiltersParameters,
} from '../components/DatabaseSearchBar';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { NgosTable } from '../components/NgosTable';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  addNgo,
  CallForTender,
  getCallForTender,
  removeNgo,
  resetCallForTenderEvent,
} from '../slices/callForTender';
import { DONORS_MAX_RESULTS, getDonor } from '../slices/donor';
import { Offer } from '../slices/financingNeed';
import {
  getNgosWithAlgorithm,
  resetNgoEvent,
  resetNgoSuggestions,
  searchNgosWithAlgorithmAndGetSuggestions,
} from '../slices/ngo';
import { LoggedIntercom } from '../utils/loggedIntercom';

const MAX_DISPLAYED_PAGES_NUMBER = 10;

const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
  },
  fondations: {
    paddingLeft: '5.59%',
    paddingTop: '4.59%',
    fontWeight: '600',
    fontFamily: "'Baloo 2'",
    fontSize: '2.125vw',
    lineHeight: '1',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2.8%',
  },
  filtersRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  select: {
    position: 'relative',
    marginRight: '28.6vw',
    marginTop: '0.7vw',
    height: '2.5vw !important',
    width: '15vw !important',
    '& .ant-select.ant-select-single.ant-select-show-arrow': {
      color: '#00BD9C',
      height: '2.5vw !important',
      width: '15vw !important',
      '& .ant-select-selector': {
        height: '2.5vw !important',
        borderRadius: '0.25vw',
        '& .ant-select-selection-item': {
          color: 'black',
          fontFamily: "'Baloo 2'",
          fontSize: '0.875vw',
          textAlign: 'center',
          ['@media screen and (min-width: 768px)']: {
            marginTop: '0%',
          },
          ['@media screen and (min-width: 1200px)']: {
            marginTop: '1%',
          },
          ['@media screen and (min-width: 1600px)']: {
            marginTop: '3%',
          },
          ['@media screen and (min-width: 2160px)']: {
            marginTop: '4.5%',
          },
        },
      },
    },
  },
  button: {
    fontSize: '0.8200vw',
    margin: 'auto',
    width: '33%',
    textAlign: 'center',
  },
  error: {
    fontSize: '1.500vw',
    paddingTop: '17%',
    paddingLeft: '16.8%',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '5%',
    marginTop: '1%',
    maxWidth: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '1.5%',
    // justifyContent: 'center',
    '& > *': {
      margin: '5px 0',
    },
  },
});

export const DatabaseFromCallForTender: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useLocation().search;
  const { callForTenderId } = useParams<{ callForTenderId: string }>();

  const [searchFilters, setSearchFilters] = useState<SearchFiltersParameters>(
    {},
  );
  const [queryParamsObject, setQueryParamsObject] = useState<
    Record<string, unknown>
  >(parse(query, { arrayFormat: 'bracket' }));

  const {
    donors,
    callForTenders,
    loading,
    searchResult,
    user,
    offset,
    classificationsFilter,
    event,
    suggestionsNgos,
    suggestionsLoading,
    financingNeedsCounts,
  } = useAppSelector(
    ({
      donor: {
        donors, // event, // loading,
      },
      callForTender: { callForTenders },
      ngo: {
        loading,
        searchResult,
        event,
        offset,
        classificationsFilter,
        suggestionsNgos,
        suggestionsLoading,
      },
      financingNeed: { financingNeedsCounts },
      auth: { user },
    }) => ({
      suggestionsLoading,
      callForTenders,
      donors,
      classificationsFilter,
      loading,
      event,
      searchResult,
      user,
      offset,
      suggestionsNgos,
      financingNeedsCounts,
    }),
  );

  const [dropdownFilterVisible, setDropdownFilterVisible] = useState(false);
  const callForTenderEvent = useAppSelector(({ callForTender: { event } }) => ({
    event,
  })).event;

  const classification = useSortedClassification();

  LoggedIntercom(
    user?.firstName + ' ' + user?.lastName,
    user?.email,
    user?._id,
    'paidNationalOffer-callForTender',
  );

  const fetchNgos = (
    page = 1,
    algorithm = 'tagsProximity',
    searchFilters: SearchFiltersParameters,
  ) => {
    updateQueryParams({ algorithm, page, ...searchFilters });
    dispatch(
      getNgosWithAlgorithm({
        callForTenderId,
        algorithm: algorithm,
        searchParameters: {
          ...searchFilters,
          offset: (page - 1) * DONORS_MAX_RESULTS,
        },
      }),
    );
  };

  const fetchNgosWithQueryParams = () => {
    const {
      page,
      algorithm,
      name,
      tags,
      statuses,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      donationTypes,
      department,
    } = queryParamsObject;
    const newSearchFilters = {
      name,
      tags,
      statuses,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      donationTypes,
      department,
    } as SearchFiltersParameters;

    setSearchFilters(newSearchFilters);

    fetchNgos(
      page ? parseInt(page as string) : undefined,
      algorithm as string,
      newSearchFilters,
    );
  };

  const updateQueryParams = (params: Record<string, unknown>) => {
    const updatedQueryParamsObject = { ...queryParamsObject, ...params };
    history.replace({
      search: stringify(updatedQueryParamsObject, { arrayFormat: 'bracket' }),
    });
    setQueryParamsObject(updatedQueryParamsObject);
  };

  useEffect(() => {
    dispatch(getDonor(user?.donorId));
  }, [user?.donorId]);

  useEffect(() => {
    fetchNgosWithQueryParams();
  }, []);

  useEffect(() => {
    if (event) {
      event.error
        ? notification.error({
            message:
              event.action == 'addFavoriteDonor'
                ? "Le porteur de projet n'a pas pu être ajouté à vos favoris"
                : "Le porteur de projet n'a pas pu être retiré de vos favoris",
            duration: 2,
          })
        : notification.success({
            message:
              event.action == 'addFavoriteDonor'
                ? 'Le porteur de projet a été ajouté à vos favoris'
                : 'Le porteur de projet a été retiré de vos favoris',
            duration: 2,
          });
      dispatch(resetNgoEvent());
    }
  }, [event]);

  useEffect(() => {
    if (callForTenderEvent) {
      callForTenderEvent.error
        ? notification.error({
            message:
              callForTenderEvent.action == 'addDonor'
                ? "Le porteur de projet n'a pas pu être ajouté au projet"
                : "Le porteur de projet n'a pas pu être retiré du projet",
            duration: 2,
          })
        : notification.success({
            message:
              callForTenderEvent.action == 'addDonor'
                ? 'Le porteur de projet a été ajouté au projet'
                : 'Le porteur de projet a été retiré du projet',
            duration: 2,
          });
      dispatch(resetCallForTenderEvent());
    }
  }, [callForTenderEvent]);

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 0;
    fetchNgos(
      newSearchPage,
      queryParamsObject.algorithm as string,
      searchFilters,
    );
  };

  const onAddNgo = (ngoId: string, callForTenderId: string) => {
    dispatch(
      addNgo({
        callForTenderId: callForTenderId,
        body: { ngoId: ngoId },
      }),
    );
    LoggedIntercom(
      user?.firstName + ' ' + user?.lastName,
      user?.email,
      user?._id,
      'callForTenderPremiumAddedNgo',
    );
  };

  const onRemoveNgo = (ngoId: string, callForTenderId: string) => {
    dispatch(
      removeNgo({
        callForTenderId: callForTenderId,
        body: { ngoId: ngoId },
      }),
    );
  };

  const onEditNgo = (ngoId: string) => {
    history.push(`/ngo/${ngoId}/edit`);
  };

  const onSelectAlgorithm = (algorithm: string) => {
    const { page } = queryParamsObject;

    fetchNgos(
      page ? parseInt(page as string) : undefined,
      algorithm,
      searchFilters,
    );
  };

  const onChangeName = (value: string) => {
    value.length
      ? dispatch(
          searchNgosWithAlgorithmAndGetSuggestions({
            callForTenderId: callForTenderId,
            searchParameters: { ...searchFilters, offset: 0, name: value },
            algorithm:
              (queryParamsObject.algorithm as string) || 'tagsProximity',
          }),
        )
      : dispatch(resetNgoSuggestions());
  };

  if (!user || !classification || !donors) {
    return <FullscreenSpin />;
  }

  function renderRadioButtons() {
    return (
      <div className={classes.select}>
        <Select
          defaultValue="tagsProximity"
          style={{ width: 120 }}
          onChange={(e) => onSelectAlgorithm(e)}
          options={[
            {
              value: 'tagsProximity',
              label: 'Proximité par Mot-clés',
            },
            {
              value: 'ngosProximity',
              label: 'Proximité avec les autres porteurs de projets',
            },
            {
              value: 'donorsProximity',
              label: 'Proximité avec les autres mécènes',
            },
          ]}
        />
      </div>
    );
  }

  useEffect(() => {
    fetchNgos(1, queryParamsObject.algorithm as string, searchFilters);
  }, [searchFilters]);

  const displayedCallForTender = useMemo<CallForTender>(
    () => callForTenders.byId[callForTenderId],
    [callForTenders.byId[callForTenderId]],
  );

  useEffect(() => {
    dispatch(getCallForTender(callForTenderId));
  }, []);

  function checkOfferCompleteValidity(callForTender: CallForTender) {
    if (
      callForTender.offers &&
      callForTender.offers.find(
        (o) =>
          (o.offer === Offer.complete && !o.expirationDate) ||
          (o.offer === Offer.complete &&
            o.expirationDate &&
            new Date(o.expirationDate) > new Date()),
      )
    ) {
      return true;
    }
    return false;
  }

  if (
    displayedCallForTender.offers &&
    checkOfferCompleteValidity(displayedCallForTender)
  ) {
    return (
      <div
        className={classes.container}
        onClick={() => setDropdownFilterVisible(false)}
      >
        <div className={classes.header}>
          <Text className={classes.fondations} style={{ color: '#5050F9' }}>
            Porteurs de Projets
          </Text>
          <div className={classes.filtersRow}>
            <DatabaseSearchBar
              classification={classification}
              onChangeName={onChangeName}
              suggestions={suggestionsNgos}
              suggestionsLoading={suggestionsLoading}
              searchParameters={searchFilters}
              setSearchFilters={setSearchFilters}
              isAutoComplete={false}
              classificationsFilter={classificationsFilter}
              isFromProject={true}
              callForTender={callForTenders.byId[callForTenderId]}
              dropdownFilterVisible={dropdownFilterVisible}
              setDropdownFilterVisible={setDropdownFilterVisible}
            />
            {renderRadioButtons()}
          </div>
        </div>
        <NgosTable
          ngos={searchResult?.resultsNgos ?? null}
          classification={classification}
          callForTenders={callForTenders.allIds.map(
            (id) => callForTenders.byId[id],
          )}
          financingNeedsCounts={financingNeedsCounts}
          callForTender={callForTenders.byId[callForTenderId]}
          onTableChange={onTableChange}
          onAddNgo={onAddNgo}
          onRemoveNgo={onRemoveNgo}
          onEditNgo={onEditNgo}
          loading={loading}
          pagination={{
            total: min([
              searchResult?.resultsCount,
              DONORS_MAX_RESULTS * MAX_DISPLAYED_PAGES_NUMBER,
            ]),
            current: offset / DONORS_MAX_RESULTS + 1,
            pageSize: DONORS_MAX_RESULTS,
            showSizeChanger: false,
          }}
          role={user.role}
        />
      </div>
    );
  } else if (
    displayedCallForTender.offers &&
    !displayedCallForTender.offers.find((o) => o.offer === Offer.complete)
  ) {
    history.push('/callForTenders');
    return (
      <div>
        <p>You do not have a valid subscription to access this feature.</p>
      </div>
    );
  } else {
    return <FullscreenSpin />;
  }
};
