import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Donor } from 'src/pages/Donor';
import { ValidatedDonors } from 'src/pages/ValidatedDonors';

import { LoggedLayout } from '../containers/LoggedLayout';
import { DonorsNotRelevant } from '../pages/DonorsNotRelevant';
import { DonorsToQualify } from '../pages/DonorsToQualify';
import { DonorsToValidate } from '../pages/DonorsToValidate';
import { EditDonor } from '../pages/EditDonor';

export const ContributorSwitch: React.VFC = () => {
  return (
    <LoggedLayout>
      <Switch>
        <Route exact path="/" component={DonorsToQualify} />
        <Route exact path="/donorsToValidate" component={DonorsToValidate} />
        <Route exact path="/validatedDonors" component={ValidatedDonors} />
        <Route exact path="/donor/:id/edit" component={EditDonor} />
        <Route exact path="/donor/:donorId" component={Donor} />
        <Route exact path="/donorsNotRelevant" component={DonorsNotRelevant} />
      </Switch>
    </LoggedLayout>
  );
};
