import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal, Popover, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';
import ReadMore from 'src/components/ReadMore';
import {
  CallForTender,
  invalidateCallForTenders,
  removeCallForTender,
} from 'src/slices/callForTender';
import { getDonor } from 'src/slices/donor';
import { FullscreenSpin } from '../../components/FullscreenSpin';
import { TagList } from '../../components/TagList';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { Classification } from '../../slices/classification';
import { Offer } from '../../slices/financingNeed';

const { Title, Text, Link } = Typography;

const useStyles = createUseStyles({
  title: {
    width: '60%',
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    // alignSelf: 'center',
    lineHeight: '1 !important',
    marginBottom: '4.76% !important',
  },
  moreOutlined: {
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    cursor: 'default',
  },
  firstLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleInformations: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    // alignSelf: 'center',
    lineHeight: '1 !important',
    marginBottom: '4.76% !important',
  },
  fiche: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1vw !important',
    lineHeight: '1 !important',
    marginBottom: '1% !important',
    color: '#5050F9 !important',
  },
  subtitle: {
    marginBottom: '3.62%',
    color: '#00bd9c',
    fontWeight: 'bold',
    fontSize: '110%',
    lineHeight: 1,
  },
  leftColumn: {
    width: '53.59%',
    display: 'flex',
    flexDirection: 'column',
    padding: '6.13% 4.98% 8.42%',
    background: 'white',
    borderRadius: '0.3125vw',
  },
  titleContainer: {
    position: 'relative',
    width: 'auto',
    height: 'auto',
    display: 'flex',
    marginBottom: '3.14%',
  },
  tag: {
    marginTop: '4.76%',
    '& .ant-tag': {
      margin: '0px 1.55% 2.38% 0px',
      borderColor: '#ececf8',
      backgroundColor: '#ececf8',
    },
  },
  logoImage: {
    width: '17%',
    maxHeight: 'calc(100% + 1.11vh)',
    position: 'absolute',
    top: '-1.11vh',
    right: 0,
  },
  editButton: {
    // margin: '0 1.87% 0 0',
    fontSize: '0.875vw',
    height: '67.61%',
    // width: '21.32%',
    padding: '0.25vw 0.9375vw',
  },
  deleteButton: {
    // margin: '0 0 0 1.87%',
    fontSize: '0.875vw',
    height: '67.61%',
    width: '24.32%',
    padding: '0.25vw 0.9375vw',
    color: 'red',
    textAlign: 'center',
  },
  displayLineBreak: {
    whiteSpace: 'pre-line',
    marginBottom: '3.716%',
    textJustify: 'auto',
    textAlign: 'justify',
    wordBreak: 'break-word',
    hyphens: 'auto',
    fontSize: '0.875vw',
    ['@media screen and (max-width: 1200px)']: {
      fontSize: '9px',
    },
  },
  link: {
    color: '#00bd9c',
    ['@media screen and (max-width: 1000px)']: {
      fontSize: '7.5px',
    },
    marginBottom: '1.716%',
  },
});

interface Props {
  callForTenderId: string;
  callForTender: CallForTender;
  classification: Classification;
}

const CallForTenderLeftColumn: React.VFC<Props> = ({
  callForTenderId,
  callForTender,
  classification,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { donorId } = useParams<{ donorId: string }>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { donor, user } = useAppSelector(({ donor, auth }) => ({
    user: auth.user,
    donor: donor.donors.byId[donorId],
  }));

  // const donor = useAppSelector((state) => state.donor.donors.byId[donorId]);

  const { tags } = classification;
  if (!callForTender) return <FullscreenSpin />;

  useEffect(() => {
    dispatch(getDonor(donorId));
  }, []);

  useEffect(() => {
    console.log('callForTender', callForTender);
  }, [callForTender]);

  const renderConfirmDelete = () => (
    <Modal
      onOk={handleDeleteCallOfTender}
      okText={'Supprimer'}
      cancelText={'Annuler'}
      visible={showDeleteModal}
      onCancel={() => setShowDeleteModal(false)}
      okButtonProps={{
        danger: true,
      }}
    >
      <Text>
        {'Êtes-vous sûr de vouloir supprimer ce programme de soutien ?'}
      </Text>
    </Modal>
  );
  const handleDeleteCallOfTender = () => {
    if (callForTender?.adminDonors && callForTender.adminDonors.length > 0) {
      const donorId = callForTender?.adminDonors[0]._id || '';
      dispatch(removeCallForTender({ callForTenderId, body: { donorId } }));
      dispatch(invalidateCallForTenders());
      history.push(`donor/${donorId}`);
    }
  };
  const menuMoreOutlined = (
    <Menu>
      <>
        <Menu.Item
          onClick={() => {
            history.push(`/donor/${callForTenderId}/editTenderDonor`);
          }}
          icon={<EditOutlined style={{ color: 'blue' }} />}
        >
          {'Modifier'}
        </Menu.Item>

        {callForTender.offers &&
        !callForTender.offers.find((o) => o.offer !== Offer.visibility) ? (
          <Menu.Item
            key="delete"
            onClick={() => {
              setShowDeleteModal(true);
            }}
            icon={<DeleteOutlined style={{ color: 'red' }} />}
          >
            {'Supprimer'}
          </Menu.Item>
        ) : (
          <Menu.Item
            key="delete"
            disabled
            icon={<DeleteOutlined style={{ color: 'red' }} />}
          >
            <Popover
              placement={'right'}
              content={
                <>
                  Vous ne pouvez pas supprimer un programme de soutien ayant une
                  offre payante !
                </>
              }
            >
              {'Supprimer'}
            </Popover>
          </Menu.Item>
        )}
      </>
    </Menu>
  );

  return (
    <div className={classes.leftColumn}>
      <Title className={classes.fiche}>Fiche Programme de soutien</Title>
      <div className={classes.titleContainer}>
        <div style={{ marginBottom: 'auto', marginTop: 'auto', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={classes.firstLine}>
              <Title className={classes.title} style={{ maxWidth: '80%' }}>
                {callForTender?.name}
              </Title>
              {(user?.role === 'admin' ||
                donor?.callForTenders?.filter(
                  (v) => v === callForTenderId,
                )) && (
                <Dropdown
                  overlay={menuMoreOutlined}
                  placement={'bottomCenter'}
                  trigger={['click']}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <MoreOutlined
                      // onClick={() => history.push(`/ngo/${ngoId}/edit`)}
                      style={{ color: '#5050f9' }}
                      className={classes.moreOutlined}
                    />
                  </a>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </div>
      {callForTender?.url && (
        <Link className={classes.link} href={callForTender.url} target="_blank">
          {callForTender.url}
        </Link>
      )}
      {callForTender.tags && (
        <div className={classes.tag}>
          <TagList
            elementIds={callForTender.tags}
            collection={tags}
            color="default"
            collectionName="tags"
          />
        </div>
      )}
      {callForTender?.description && (
        <>
          <Text className={classes.subtitle}>Description</Text>
          <ReadMore
            description={callForTender?.description}
            maximumNumberOfCharacters={1267}
          ></ReadMore>{' '}
        </>
      )}
      {user?.role === 'admin' && callForTender?.comments && (
        <>
          <Text className={classes.subtitle}>Commentaires</Text>
          <Text className={classes.displayLineBreak}>
            {callForTender?.comments}
          </Text>
        </>
      )}
      {renderConfirmDelete()}
    </div>
  );
};

export default CallForTenderLeftColumn;
