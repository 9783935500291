import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { assignOrganizationType, fetchCurrentUser } from '../slices/auth';

const useStyles = createUseStyles({
  container: {
    backgroundImage: `url("../../images/dashboardLogo.png")`,
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'right',
    width: '100%',
    height: '100%',
  },
  textColumn: {
    marginTop: '4%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginBottom: '7%',
  },
  textRow: {
    marginBottom: '-10%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  textBlue: {
    color: '#1D4ED8',
    fontSize: '3.3vw',
    marginRight: '1vw',
    marginLeft: '1vw',
  },
  textBlack: {
    color: '#292929',
    fontStyle: 'italic',
    fontSize: '1.8vw',
    marginBottom: '1.5vw',
  },
  imgRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '-6.5%',
  },
  img1: {
    width: '15vw',
    height: '15vw',
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("/images/signUpImg1.webp")`,
  },
  img2: {
    width: '15vw',
    height: '15vw',
    marginBottom: '-66%',
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("/images/signUpImg2.webp")`,
  },
  img3: {
    width: '15vw',
    height: '15vw',
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("/images/signUpImg3.webp")`,
  },
  img4: {
    width: '18vw',
    height: '18vw',
    marginTop: '-7%',
    marginLeft: '-1%',
    marginRight: '1.5%',
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("/images/signUpImg4.webp")`,
  },
  img5: {
    width: '15vw',
    height: '15vw',
    marginBottom: '-28%',
    marginTop: '30%',
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("/images/signUpImg5.webp")`,
  },
  img6: {
    width: '15vw',
    height: '15vw',
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("/images/signUpImg6.webp")`,
  },
  img7: {
    width: '15vw',
    height: '15vw',
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("/images/signUpImg7.webp")`,
  },
  imgColumn1: {
    marginLeft: '1.5%',
    display: 'flex',
    flexDirection: 'column',
  },
  imgColumn2: {
    marginRight: '1.5%',
    display: 'flex',
    flexDirection: 'column',
  },
  signUpText: {
    fontSize: '2vw',
    marginTop: '-6%',
  },
  btngroup: {
    marginTop: '-0.5%',
    paddingBottom: '5%',
  },
  buttonPrevious: {
    height: '3vw',
    fontSize: '0.8vw',
    border: '1px solid #5050F6',
    backgroundColor: 'white',
    color: '#5050F6',
    borderRadius: '0.5vw',
    '&:hover': {
      border: '1px solid #5050F6',
      backgroundColor: '#5050F6',
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
});

interface Props {
  setIsNavBarHidden?: (isNavBarHidden: boolean) => void;
}

export const ChooseNgoOrDonor: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory();

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  useEffect(() => {
    if (setIsNavBarHidden) {
      setIsNavBarHidden(true);
    }
  }, []);

  useEffect(() => {
    fetchCurrentUser();
  }, [user]);

  if (!user) {
    return <FullscreenSpin />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <div className={classes.textColumn}>
          <div className={classes.textRow}>
            <div className={classes.textBlue}>
              <p>Un seul lieu</p>
            </div>
            <div className={classes.textBlack}>
              <p>pour concrétiser des projets</p>
            </div>
          </div>
          <div className={classes.textRow}>
            <div className={classes.textBlack}>
              <p>pour se trouver</p>
            </div>
            <div className={classes.textBlue}>
              <p>autour du mécénat</p>
            </div>
          </div>
        </div>
        <div className={classes.imgRow}>
          <div className={classes.img1}></div>
          <div className={classes.imgColumn1}>
            <div className={classes.img2}></div>
            <div className={classes.img3}></div>
          </div>
          <div className={classes.img4}></div>
          <div className={classes.imgColumn2}>
            <div className={classes.img5}></div>
            <div className={classes.img6}></div>
          </div>
          <div className={classes.img7}></div>
        </div>
        <div className={classes.signUpText}>
          <p>Je m’inscris en tant que</p>
        </div>
        <div className={classes.btngroup}>
          <Button
            type="primary"
            className={classes.buttonPrevious}
            style={{
              width: '15vw',
              height: '4vw',
              fontSize: '1vw',
              marginRight: '1.5vw',
            }}
            onClick={() => {
              dispatch(
                assignOrganizationType({
                  updateUserDto: { organisationType: 'PROJECT_HOLDER' },
                }),
              ).then((action: { payload: any }) => {
                if (action.payload) {
                  history.push('/NgoFirstSteps');
                }
              });
            }}
          >
            Porteur de projet
          </Button>
          <Button
            type="primary"
            className={classes.buttonPrevious}
            style={{
              width: '15vw',
              height: '4vw',
              fontSize: '1vw',
              marginRight: '1vw',
            }}
            onClick={() => {
              dispatch(
                assignOrganizationType({
                  updateUserDto: { organisationType: 'MECENE' },
                }),
              ).then((action: { payload: any }) => {
                if (action.payload) {
                  history.push('/DonorFirstSteps');
                }
              });
            }}
          >
            Mécène
          </Button>
        </div>
      </div>
    </div>
  );
};
