import {
  CheckCircleFilled,
  LinkedinFilled,
  LinkOutlined,
  MinusCircleOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  ConfigProvider,
  Dropdown,
  Empty,
  Menu,
  Popover,
  Table,
  TablePaginationConfig,
  Typography,
} from 'antd';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { FinancingNeed } from 'src/slices/financingNeed';
import { CallForTender } from '../slices/callForTender';
import { Ngo } from '../slices/ngo';
import { OddImages } from './OddImages';
import { TagList } from './TagList';

const { SubMenu } = Menu;
const { Link, Text } = Typography;

interface NgosTableProps {
  classification?: any;
  ngos: Ngo[] | null;
  financingNeed?: FinancingNeed;
  callForTenders: CallForTender[] | null;
  callForTender?: CallForTender;
  onTableChange?: (pagination: TablePaginationConfig) => void;
  onAddNgo: (ngoId: string, dealFlowId: string) => void;
  onRemoveNgo: (ngoId: string, dealFlowId: string) => void;
  onEditNgo?: (ngoId: string) => void;
  loading: boolean;
  pagination: false | TablePaginationConfig | undefined;
  role?: string;
  financingNeedsCounts?: { [key: string]: number };
}

const useStyles = createUseStyles({
  table: {
    maxWidth: '100%',
    padding: '1.15% 3.45%',
    tableLayout: 'fixed',
    '& .ant-table': {
      backgroundColor: 'transparent',
    },
    '& .ant-table table': {
      borderSpacing: '0 2.22vh',
      '& .ant-table-measure-row': {
        display: 'none',
      },
    },
    '& .ant-table-row': {
      backgroundColor: 'transparent',
      height: '11.92%',
    },
    '& .ant-table-cell-fix-left': {
      paddingLeft: '2.467% !important',
      paddingRight: '0px !important',
      borderTopLeftRadius: '2.029%',
      borderBottomLeftRadius: '2.029%',
      overflow: 'hidden',
    },
    '& .ant-table-cell-fix-right': {
      paddingLeft: '2.878% !important',
      paddingRight: '2.467% !important',
      verticalAlign: 'middle !important',
      borderTopRightRadius: '2.029%',
      borderBottomRightRadius: '2.029%',
    },
    '& .ant-table-cell': {
      height: '6vh',
      maxHeight: '6vh',
      verticalAlign: 'top',
      background: 'white',
      padding: '1.31% 1.64% 1.31% 2.47%',
    },
  },
  name: {
    fontWeight: '600',
    fontSize: '1.1875vw',
    ['@media screen and (max-width: 950px)']: {
      fontSize: '11px',
    },
    cursor: 'pointer',
    fontFamily: "'Baloo 2'",
    textOverflow: 'ellipsis',
  },
  commonTags: {
    fontSize: '1.08vw',
    fontFamily: "'Baloo 2'",
    cursor: 'pointer',
  },
  socialsIcon: {
    color: '#5050F9',
    fontSize: '1.1875vw',
    margin: '0.625vw 1.25vw 0.625vw 0.25vw',
  },
  tags: {
    '& .tag-list': {
      fontSize: 0,
      margin: 0,
      '& .ant-tag': {
        margin: '0px 0.25vw 0px 0px',
        border: 'none',
        fontSize: '0.625vw',
        ['@media screen and (max-width: 1200px)']: {
          fontSize: '7.5px',
        },
        backgroundColor: 'transparent',
        padding: '0.05vw 0.4vw 0.05vw 0.4vw',
        lineHeight: 1.5715,
        marginBottom: '0.4vw',
        wordBreak: 'break-word',
        whiteSpace: 'unset',
      },
      '& .ant-typography': {
        fontSize: '0.625vw',
        lineHeight: 1.5715,
        display: 'inline list-item',
        margin: '0px 0.25vw 0px 0px',
      },
    },
  },
  textCallForTenders: {
    fontSize: '0.625vw',
    height: '10px',
    padding: 0,
    lineHeight: 1.5715,
    wordBreak: 'break-word',
    whiteSpace: 'unset',
    display: 'inline list-item',
    margin: 0,
  },
  descriptionText: {
    cursor: 'default',
  },
  descriptionPopup: {
    maxWidth: '25vw',
    maxHeight: '40vh',
    overflowY: 'auto',
  },
  subtitle: {
    color: '#00bd9c',
    fontWeight: 'bold',
    fontSize: '0.625vw',
    ['@media screen and (max-width: 1200px)']: {
      fontSize: '7.5px',
    },
  },
  tag: {
    // margin: '0.093vw 0 0.093vw',
    // cursor: 'default',
    // border: 'none',
    // padding: '0.1875vw 0.5vw',
    fontSize: '0.68vw',
    lineHeight: '1vw',
    // whiteSpace: 'nowrap',
    // marginLeft: '0.25vw',
    // marginRight: '0.25vw',
  },
  plusButton: {
    height: 'auto',
    width: '1vw',
    marginRight: '3vw',
    marginLeft: '-21px',
    border: 'none',
    top: '4%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative',
    '& .hover-icon': {
      visibility: 'hidden',
    },
    '&:hover .plus-icon': {
      display: 'none',
    },
    '&:hover .hover-icon': {
      visibility: 'visible',
    },
  },
});

export const NgosTable: React.VFC<NgosTableProps> = ({
  classification,
  ngos,
  callForTenders,
  callForTender,
  onTableChange,
  onAddNgo,
  onRemoveNgo,
  onEditNgo,
  loading,
  pagination,
  role,
  financingNeedsCounts,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const renderCallForTendersList = (
    callForTenderNeedsOptions: CallForTender[],
    ngoId: string,
    add: boolean,
  ) => (
    <>
      {callForTenderNeedsOptions.map((callForTenderOption, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            add
              ? onAddNgo(ngoId, callForTenderOption._id)
              : onRemoveNgo(ngoId, callForTenderOption._id);
          }}
        >
          {callForTenderOption.name}
        </Menu.Item>
      ))}
    </>
  );

  const renderAddRemoveNgoToProjectMenu = (ngo: Ngo) =>
    callForTender?.ngos.includes(ngo._id) ? (
      <Menu.Item
        onClick={() => {
          if (callForTender) {
            onRemoveNgo(ngo._id, callForTender._id);
          }
        }}
      >
        {'Retirer des porteurs de projets suivis'}
      </Menu.Item>
    ) : (
      <Menu.Item
        onClick={() => {
          if (callForTender) {
            onAddNgo(ngo._id, callForTender._id);
          }
        }}
      >
        {'Ajouter aux porteurs de projets suivis'}
      </Menu.Item>
    );

  const renderAddNgoSubMenu = (ngo: Ngo) => {
    const callForTendersOptions = callForTenders?.filter(
      (callForTender) => !callForTender.ngos.includes(ngo._id),
    );
    return (
      !!callForTendersOptions?.length && (
        <SubMenu title={'Ajouter à un projet'}>
          {renderCallForTendersList(callForTendersOptions, ngo._id, true)}
        </SubMenu>
      )
    );
  };

  const renderRemoveNgoSubMenu = (ngo: Ngo) => {
    const callForTendersOptions = callForTenders?.filter((callForTender) =>
      callForTender.ngos.includes(ngo._id),
    );
    return (
      !!callForTendersOptions?.length && (
        <SubMenu title={"Retirer d'un projet"}>
          {renderCallForTendersList(callForTendersOptions, ngo._id, false)}
        </SubMenu>
      )
    );
  };

  const renderEditNgo = (ngo: Ngo) => {
    return (
      <Menu.Item onClick={() => onEditNgo && onEditNgo(ngo._id)}>
        Modifier
      </Menu.Item>
    );
  };

  const renderSubTitle = (text: string) => (
    <Text className={classes.subtitle}>{text}</Text>
  );

  const calculateNbOfFinancingNeeds = (ngoId?: string) => {
    if (financingNeedsCounts && ngoId) {
      const count = financingNeedsCounts[ngoId];
      return count || 0;
    }
    return 0;
  };

  const renderNbFinancingNeeds = (nbFinancingNeeds: number) => {
    if (nbFinancingNeeds === 0) {
      return 'Aucun projet en cours';
    } else if (nbFinancingNeeds === 1) {
      return '1 projet en cours';
    }
    return nbFinancingNeeds + ' projets en cours';
  };

  const columns = useMemo(
    () =>
      classification
        ? [
            {
              title: 'Nom',
              dataIndex: 'name',
              key: '_id',
              width: '10.00%',
              fixed: 'left' as const,
              render: function renderName(name?: string, record?: Ngo) {
                if (!record) return null;
                return (
                  <div>
                    <Popover
                      placement="topLeft"
                      content={
                        <div className={classes.descriptionPopup}>
                          <div className={classes.descriptionText}>
                            {record.description}
                          </div>
                        </div>
                      }
                    >
                      <div>
                        <div
                          className={classes.name}
                          onClick={() =>
                            history.push(
                              `/ngo/${record._id}${
                                callForTender
                                  ? `?callForTenderId=${callForTender._id}`
                                  : ''
                              }`,
                            )
                          }
                        >
                          {name}
                        </div>
                        {/* <div style={{ cursor: 'pointer' }}>
                            <EllipsisOutlined />
                          </div> */}
                      </div>
                    </Popover>
                  </div>
                );
              },
            },
            {
              title: 'Boutons',
              dataIndex: 'name',
              key: '_id',
              width: '10.00%',
              fixed: 'left' as const,
              render: function renderName(name?: string, record?: Ngo) {
                if (!record) return null;
                const menu = (
                  <Menu>
                    {callForTender && renderAddRemoveNgoToProjectMenu(record)}
                    {!callForTender && renderAddNgoSubMenu(record)}
                    {!callForTender && renderRemoveNgoSubMenu(record)}
                    {role && role === 'admin' && renderEditNgo(record)}
                  </Menu>
                );
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      borderRight: 'solid',
                      borderRightColor: '#f6f5fa',
                      height: '6.69vw',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          display: 'flex',
                          marginLeft: 'auto',
                          justifyContent: 'space-between',
                        }}
                      >
                        {record.websiteUrl && (
                          <Link href={record.websiteUrl} target="_blank">
                            <LinkOutlined className={classes.socialsIcon} />
                          </Link>
                        )}
                        {record.linkedinUrl && (
                          <Link href={record.linkedinUrl} target="_blank">
                            <LinkedinFilled
                              className={classes.socialsIcon}
                              style={{ marginLeft: '-0.625vw' }}
                            />
                          </Link>
                        )}
                        {callForTender && role !== 'admin' ? (
                          <Button
                            className={classes.plusButton}
                            onClick={() => {
                              if (callForTender?.ngos.includes(record._id)) {
                                onRemoveNgo(record._id, callForTender._id);
                              } else {
                                onAddNgo(record._id, callForTender._id);
                              }
                            }}
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              boxShadow: 'none',
                            }}
                          >
                            <span className="plus-icon">
                              {callForTender?.ngos.includes(record._id) ? (
                                <CheckCircleFilled
                                  style={{
                                    fontSize: '1.25vw',
                                    color: '#5050F9',
                                  }}
                                />
                              ) : (
                                <PlusCircleOutlined
                                  style={{
                                    fontSize: '1.25vw',
                                    color: '#5050F9',
                                  }}
                                />
                              )}
                            </span>
                            <span className="hover-icon">
                              {callForTender?.ngos.includes(record._id) ? (
                                <MinusCircleOutlined
                                  style={{ fontSize: '1.25vw', color: 'red' }}
                                />
                              ) : (
                                <PlusCircleFilled
                                  style={{
                                    fontSize: '1.25vw',
                                    color: '#5050F9',
                                  }}
                                />
                              )}
                            </span>
                          </Button>
                        ) : (
                          <Dropdown overlay={menu}>
                            <Button
                              className={classes.plusButton}
                              onClick={(e) => e.preventDefault()}
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                              }}
                            >
                              <span className="plus-icon">
                                {callForTender?.ngos.includes(record._id) ? (
                                  <CheckCircleFilled
                                    style={{
                                      fontSize: '1.25vw',
                                      color: '#5050F9',
                                    }}
                                  />
                                ) : (
                                  <PlusCircleOutlined
                                    style={{
                                      fontSize: '1.25vw',
                                      color: '#5050F9',
                                    }}
                                  />
                                )}
                              </span>
                              <span className="hover-icon">
                                {callForTender?.ngos.includes(record._id) ? (
                                  <MinusCircleOutlined
                                    style={{ fontSize: '1.25vw', color: 'red' }}
                                  />
                                ) : (
                                  <PlusCircleFilled
                                    style={{
                                      fontSize: '1.25vw',
                                      color: '#5050F9',
                                    }}
                                  />
                                )}
                              </span>
                            </Button>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              title: 'Mots clés',
              dataIndex: 'tags',
              key: 'tags',
              width: '14%',
              render: function renderTags(tagIds: string[]) {
                return (
                  <div className={classes.tags}>
                    {renderSubTitle('Mots clés')}
                    <TagList
                      elementIds={tagIds ?? []}
                      maxElements={3}
                      collection={classification.tags}
                      color="default"
                      collectionName="tags"
                      highlightedIds={callForTender?.tags ?? []}
                    />
                  </div>
                );
              },
            },
            {
              title: 'Statut',
              dataIndex: 'status',
              key: 'status',
              width: '12.748%',
              render: function renderTags(statusIds?: string[], record?: Ngo) {
                return (
                  <div>
                    <div className={classes.tags}>
                      {renderSubTitle('Statut')}
                      <br />
                      <Text className={classes.tag}>{record?.status}</Text>
                      {/*<Typography.Text className={classes.otherTags}>*/}
                      {/*  {record?.status}*/}
                      {/*</Typography.Text>*/}
                      {/*<Tag className={classes.tag} key={status}>*/}
                      {/*  {record?.status}*/}
                      {/*</Tag>*/}
                    </div>
                    <div className={classes.tags}>
                      {renderSubTitle("Domaines d'activité")}
                      <TagList
                        elementIds={record?.activityDomains ?? []}
                        maxElements={2}
                        collection={classification.activityDomains}
                        color="default"
                        collectionName="activityDomains"
                      />
                    </div>
                  </div>
                );
              },
            },
            {
              title: "Zones d'activité",
              dataIndex: 'activityZones',
              key: 'activityZones',
              width: '13.159%',
              render: function renderTags(tagIds?: string[], record?: Ngo) {
                const nbFinancingNeeds = calculateNbOfFinancingNeeds(
                  record?._id,
                );
                return (
                  <div className={classes.tags ?? []}>
                    {/*{renderSubTitle("Zones d'activité")}*/}
                    {/*<TagList*/}
                    {/*  elementIds={tagIds ?? []}*/}
                    {/*  maxElements={3}*/}
                    {/*  collection={classification.activityZones}*/}
                    {/*  color="default"*/}
                    {/*  collectionName="activityZones"*/}
                    {/*/>*/}
                    {renderSubTitle('Bénéficiaires cibles')}
                    <TagList
                      elementIds={record?.targetPopulations ?? []}
                      collection={classification.targetPopulations}
                      maxElements={3}
                      color="default"
                      collectionName="targetPopulations"
                    />
                    <div>
                      {renderSubTitle('Projets en cours')}
                      <br />
                      <div className={classes.textCallForTenders}>
                        {renderNbFinancingNeeds(nbFinancingNeeds)}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              title: 'ODD',
              dataIndex: 'sustainableDevelopmentGoals',
              key: 'sustainableDevelopmentGoals',
              width: '10.69%',
              render: function renderSDG(sdgIds?: string[]) {
                return (
                  <div className={classes.tags} style={{ maxWidth: '10vw' }}>
                    {renderSubTitle('ODD')}
                    {/*<TagList*/}
                    {/*  elementIds={sdgIds ?? []}*/}
                    {/*  collection={classification.sustainableDevelopmentGoals}*/}
                    {/*  maxElements={4}*/}
                    {/*  color="default"*/}
                    {/*  collectionName="sustainableDevelopmentGoals"*/}
                    {/*/>*/}
                    <OddImages
                      sustainableDevelopmentGoals={
                        classification.sustainableDevelopmentGoals
                      }
                      oddIds={sdgIds ?? []}
                      size={25}
                    />
                  </div>
                );
              },
            },
          ]
        : [],
    [classification, callForTenders],
  );

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description={`Aucun résultat pour le moment...`} />
      )}
    >
      <Table
        className={classes.table}
        dataSource={ngos ?? undefined}
        columns={columns}
        rowKey="_id"
        onChange={onTableChange}
        loading={loading || !ngos || !classification}
        pagination={pagination}
        scroll={{ x: true }}
        showHeader={false}
      />
    </ConfigProvider>
  );
};
