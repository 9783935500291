import React from 'react';
import { Button, Card, Modal, Popover, Typography, Switch } from 'antd';
import { createUseStyles } from 'react-jss';
import closeIcon from '../assets/icons/closeIcon.png';
import { CallForTender } from 'src/slices/callForTender';
import { FinancingNeed, Offer } from 'src/slices/financingNeed';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;
const useStyles = createUseStyles({
  title: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    alignSelf: 'center',
    lineHeight: '1 !important',
  },
  subtitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.5vw !important',
    alignSelf: 'center',
    lineHeight: '1 !important',
  },
  cell: {
    padding: '2px',
  },
  header: {
    fontWeight: 'bold',
    width: '150px',
  },
  column: {
    display: 'flex',
    flex: '25%',
    // border: '1px solid',
  },
  row: {
    display: 'flex',
    flex: '100%',
    flexShrink: 0,
    flexGrow: 0,
    flexDirection: 'row',
    padding: '10px',
  },
  tag: {
    color: 'white',
    cursor: 'pointer',
    padding: '2px 5px',
    margin: '5px 0',
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    marginRight: '1vw',
    borderRadius: '1.5vw',
    backgroundColor: '#5050f6',
    fontSize: '0.7vw',
    height: '2vw',
    border: 'none',
  },
  limitTagsText: {
    textAlign: 'right',
    margin: '8px 0',
  },
  headerButtons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '10px',
    marginBottom: '10px',
  },
  projectContainer: {
    display: 'grid',
    columnGap: '3.372%',
    gridTemplateColumns: 'repeat(auto-fill, minmax(17.45vw, 0fr))',
  },
  card: {
    backgroundColor: '#fcfcfc',
    minHeight: '27vw',
    marginTop: '14.71%',
    width: '17.45vw',
    borderRadius: '1vw',
    webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    '& .ant-card-body': {
      padding: '0 5%',
      fontSize: '0.8125vw',
      ['@media screen and (max-width: 1000px)']: {
        fontSize: '8.5px',
      },
    },
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  cardTitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: 'bold',
    fontSize: '1vw !important',
    lineHeight: '1 !important',
    margin: '0 0 4.76% 0 !important',
    minWidth: '12vw',
    maxWidth: '12vw',
  },
  switchDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
    gap: '10px',
  },
  openProjectButton: {
    color: '#5050F9',
    fontWeight: 'bold',
    paddingBottom: '2%',
    '& .ant-btn': {
      width: '100%',
      fontSize: '0.6875vw',
      ['@media screen and (max-width: 1000px)']: {
        fontSize: '7.5px',
      },
      whiteSpace: 'normal',
      wordBreak: 'break-all',
      height: '100%',
      padding: '2.14% 8.02%',
    },
  },
  cardButton: {
    backgroundColor: '#fcfcfc',
    border: '1px solid #5050F6',
    borderRadius: '0.4vw',
    color: '#5050F6',
    '&:hover': {
      backgroundColor: '#5050F6',
      border: '1px solid #5050F6',
      color: 'white',
    },
  },
  buttonDonor: {
    color: '#5050F9',
    fontWeight: 'bold',
    paddingBottom: '2%',
    '& .ant-btn': {
      width: '100%',
      fontSize: '0.6875vw',
      ['@media screen and (max-width: 1000px)']: {
        fontSize: '7.5px',
      },
      whiteSpace: 'normal',
      wordBreak: 'break-all',
      height: '100%',
      padding: '2.14% 8.02%',
    },
  },
  nationaleButton: {
    marginTop: '0.4vw',
    backgroundColor: '#2dc4a8',
    border: '1px solid #2dc4a8',
    borderRadius: '0.4vw',
    color: 'white',
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid #2dc4a8',
      color: '#2dc4a8',
    },
  },
  visibilityButton: {
    marginTop: '0.4vw',
    backgroundColor: '#5050F6',
    border: '1px solid #5050F6',
    borderRadius: '0 0 0.4vw 0.4vw',
    color: 'white',
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid #5050F6',
      color: '#5050F6',
    },
  },
});

interface OfferModalProps {
  isVisible: boolean;
  requestClose: () => void;
  type: 'callForTender' | 'financingNeed';
  callForTenders?: CallForTender[];
  financingNeeds?: FinancingNeed[];
  checkHasPaidOfferCFT?: (callForTender: CallForTender) => string;
  checkHasPaidOfferFN?: (financingNeed: FinancingNeed) => boolean;
}

export const OfferModal: React.VFC<OfferModalProps> = ({
  isVisible,
  requestClose,
  type,
  callForTenders,
  financingNeeds,
  checkHasPaidOfferCFT,
  checkHasPaidOfferFN,
}) => {
  const classes = useStyles();
  const history = useHistory();

  function renderCallForTenders() {
    if (callForTenders && checkHasPaidOfferCFT) {
      return (
        <div className={classes.projectContainer}>
          {Object.values(callForTenders).map(
            (callForTender) =>
              checkHasPaidOfferCFT(callForTender) && (
                <Card
                  bordered={false}
                  hoverable
                  key={callForTender._id}
                  className={classes.card}
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push(`/subscription/${callForTender._id}/false`);
                  }}
                >
                  <div style={{ height: '100%', padding: '2vw 0 2vw 0' }}>
                    <div className={classes.cardHeader}>
                      <div>
                        <Title className={classes.cardTitle}>
                          {callForTender.name}
                        </Title>
                      </div>
                      <div className={classes.switchDiv}>
                        <Popover
                          content={
                            <div>
                              <p>
                                Gris : les porteurs de projets ne pourront pas
                                voir le programme de soutien
                              </p>
                              <p>
                                Bleu : les porteurs de projets pourront voir le
                                programme de soutien
                              </p>
                            </div>
                          }
                          trigger="hover"
                        >
                          <Switch checked={callForTender.published} />
                        </Popover>
                      </div>
                    </div>
                    <br />
                    <div className={classes.openProjectButton}>
                      <Button
                        style={{ padding: '0.8vw 0 0.8vw 0' }}
                        className={classes.cardButton}
                      >
                        Ouvrir le programme de soutien
                      </Button>
                    </div>
                    <div className={classes.openProjectButton}>
                      <Button
                        style={{ padding: '0.8vw 0 0.8vw 0' }}
                        className={classes.cardButton}
                      >
                        Tableau de suivi
                      </Button>
                    </div>
                    <div className={classes.buttonDonor}>
                      <Button
                        style={{
                          padding: '0.8vw 0 0.8vw 0',
                          wordBreak: 'normal',
                        }}
                        className={classes.nationaleButton}
                        onClick={(event) => {
                          event.stopPropagation();
                          history.push(
                            `/subscription/${callForTender._id}/false`,
                          );
                        }}
                      >
                        Recherche porteurs de projet France
                      </Button>
                    </div>
                    <div className={classes.buttonDonor}>
                      <>
                        {callForTender.offers.length === 1 && (
                          <Button
                            type={'text'}
                            style={{ padding: '0.5vw 0 0.5vw 0' }}
                            className={classes.visibilityButton}
                          >
                            {callForTender.offers &&
                              callForTender.offers.find(
                                (o) => o.offer === Offer.visibility,
                              ) && <>Offre Visibilité</>}
                            <br />
                          </Button>
                        )}
                      </>
                    </div>
                  </div>
                </Card>
              ),
          )}
        </div>
      );
    } else {
      return <></>;
    }
  }

  function renderFinancingNeeds() {
    if (financingNeeds && checkHasPaidOfferFN) {
      return (
        <div className={classes.projectContainer}>
          {Object.values(financingNeeds).map(
            (financingNeed) =>
              checkHasPaidOfferFN(financingNeed) && (
                <Card
                  bordered={false}
                  hoverable
                  key={financingNeed._id}
                  className={classes.card}
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push(`/subscription/${financingNeed._id}/false`);
                  }}
                >
                  <div style={{ height: '100%', padding: '2vw 0 2vw 0' }}>
                    <div className={classes.cardHeader}>
                      <div>
                        <Title className={classes.cardTitle}>
                          {financingNeed.name}
                        </Title>
                      </div>
                      <div className={classes.switchDiv}>
                        <Popover
                          content={
                            <div>
                              <p>
                                Désactivé : les mécènes ne pourront pas voir le
                                projet
                              </p>
                              <p>
                                Activé : les mécènes pourront voir le projet
                              </p>
                            </div>
                          }
                          trigger="hover"
                        >
                          <Switch checked={financingNeed.published} />
                        </Popover>
                      </div>
                    </div>
                    <br />
                    <div className={classes.openProjectButton}>
                      <Button
                        style={{ padding: '0.8vw 0 0.8vw 0' }}
                        className={classes.cardButton}
                      >
                        Ouvrir le projet
                      </Button>
                    </div>
                    <div className={classes.openProjectButton}>
                      <Button
                        style={{ padding: '0.8vw 0 0.8vw 0' }}
                        className={classes.cardButton}
                      >
                        Tableau de suivi
                      </Button>
                    </div>
                    <div className={classes.buttonDonor}>
                      <Button
                        style={{
                          padding: '0.8vw 0 0.8vw 0',
                          wordBreak: 'normal',
                        }}
                        className={classes.nationaleButton}
                        onClick={(event) => {
                          event.stopPropagation();
                          history.push(
                            `/subscription/${financingNeed._id}/false`,
                          );
                        }}
                      >
                        Mécènes correspondants
                      </Button>
                    </div>
                    <div className={classes.buttonDonor}>
                      <>
                        {financingNeed.offers.length === 1 && (
                          <Button
                            type={'text'}
                            style={{ padding: '0.5vw 0 0.5vw 0' }}
                            className={classes.visibilityButton}
                          >
                            {financingNeed.offers &&
                              financingNeed.offers.find(
                                (o) => o.offer === Offer.visibility,
                              ) && <>Offre Visibilité</>}
                            <br />
                          </Button>
                        )}
                      </>
                    </div>
                  </div>
                </Card>
              ),
          )}
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <Modal
      visible={isVisible}
      closable={false}
      onCancel={() => {
        requestClose();
      }}
      onOk={() => {
        requestClose();
      }}
      width={'100%'}
      style={{
        top: '1vw',
        marginLeft: '5vw',
        borderRadius: '0.5vw',
        maxWidth: '90%',
      }}
      footer={null}
    >
      <div style={{ marginLeft: '2vw', marginBottom: '2vw' }}>
        <img
          src={closeIcon}
          alt="Close"
          style={{
            width: '1.3vw', // Adjust the width to fit your desired size
            position: 'absolute',
            top: '1.2vw',
            right: '1.5vw',
            cursor: 'pointer',
          }}
          onClick={() => {
            requestClose();
          }}
        />
        <Title className={classes.title}>
          {
            'Choisissez le programme de soutien que vous voulez faire passer en premium !'
          }
        </Title>
        <Title className={classes.subtitle}>
          {'Découvrez les nombreux avantages de passez en premium sur Sonar.'}
        </Title>
        {type === 'callForTender' ? (
          renderCallForTenders()
        ) : type === 'financingNeed' ? (
          renderFinancingNeeds()
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};
