import React, { useEffect, useMemo, useState } from 'react';
import { Image, Layout, Typography, message } from 'antd';
import {
  LogoutOutlined,
  FolderOpenOutlined,
  DatabaseOutlined,
  MenuOutlined,
  EditOutlined,
  CheckOutlined,
  TeamOutlined,
  BlockOutlined,
  DollarCircleOutlined,
  CalendarOutlined,
  ZoomInOutlined,
  LineChartOutlined,
  LinkOutlined,
  HomeOutlined,
  PlusCircleOutlined,
  BellOutlined,
  UnlockOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { logout } from '../slices/auth';
import logo from '../assets/sonarLogo.svg';
import { NavBar } from './NavBar';
import { NavItemProps } from './NavItem';
import { Header } from 'antd/lib/layout/layout';
import { theme } from 'src/constants/theme';
import { FinancingNeed, getFinancingNeeds } from 'src/slices/financingNeed';
import {
  CallForTender,
  getCallForTenderFromDonorId,
} from 'src/slices/callForTender';

const { Content, Sider } = Layout;

interface Props {
  children: React.ReactNode;
  isNavBarHidden?: boolean;
}

const useStyles = createUseStyles({
  sider: {
    overflowY: 'auto',
    height: '100vh',
    left: 0,
    minWidth: '18.375% !important',
    width: '18.375% !important',
    maxWidth: '18.375% !important',
    flex: 'none !important',
    '& .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left': {
      borderRight: '1px solid transparent',
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 20vw)',
    },
    borderRight: '4px solid rgba(58, 74, 255, 0.3)',
  },
  logo: {
    width: '10%',
    filter:
      'invert(56%) sepia(91%) saturate(7495%) hue-rotate(237deg) brightness(102%) contrast(95%)',
  },
  logoText: {
    lineHeight: 1,
    fontSize: '2vw',
    fontWeight: '600',
    display: 'block',
    marginBottom: '10%',
    color: '#5050F9',
    fontFamily: "'Baloo 2'",
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  mobileSiderTitle: {
    margin: '20px',
    color: theme.palette.common.white,
  },
  icon: {
    color: 'black',
    fontSize: '1.2vw !important',
    marginRight: '6.67% !important',
  },
  iconRed: {
    color: 'red',
    fontSize: '1.375vw !important',
    marginRight: '6.67% !important',
  },
  siteLayout: {
    // background:
    //   'radial-gradient(1000px 2000px at left 38vh, white 0%, transparent 15%),\
    //   radial-gradient(500% 225% at right 38vh, white 0%, #f1f0fd 2%, transparent 15%),\
    //   radial-gradient(ellipse at 30vw 75vh, #d0cff9 0%, transparent 50%),\
    //   radial-gradient(ellipse at top right, #babaec 0%, transparent 60%),\
    //   radial-gradient(ellipse at top left, #f3f3ff 0%, transparent 75%),\
    //   radial-gradient(ellipse at bottom left, #f2f2fe, transparent 75%),\
    //   radial-gradient(ellipse at bottom right, #e4e3f2, transparent 75%)',
    //   background:
    //     'radial-gradient(ellipse at top right, #d2dcfd 0%, #f0f2ff 20%, transparent 50%),\
    // radial-gradient(ellipse at top left, #fdfefe 0%, #f4f7ff 20%, transparent 50%),\
    // radial-gradient(ellipse at bottom left, #e5ecfe, #f2f5ff 20%, transparent 50%),\
    // radial-gradient(ellipse at bottom right, #f9fdfc, #f9fdfd 15%, transparent 50%)',
    background:
      'radial-gradient(ellipse at top right, #ffffff 0%, #ffffff 20%, transparent 50%),\
  radial-gradient(ellipse at top left, #ffffff 0%, #ffffff 20%, transparent 50%),\
  radial-gradient(ellipse at bottom left, #ffffff, #ffffff 20%, transparent 50%),\
  radial-gradient(ellipse at bottom right, #ffffff, #ffffff 15%, transparent 50%)',
  },
  label: {
    fontSize: '0.9375vw',
    position: 'relative',
    bottom: '10.53%',
    color: 'black',
  },
  labelRed: {
    color: 'red',
    fontSize: '0.9375vw',
    position: 'relative',
    bottom: '10.53%',
  },
  mainLabel: {
    fontSize: '0.8vw',
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    right: '0.4vw',
  },
  subLabel: {
    fontSize: '0.6vw',
    width: '100%',
    textAlign: 'center',
  },
  newProject: {
    border: '0.1vw dashed black',
    width: '13vw',
    height: '7vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    gap: '0.5vw',
  },
});

export const LoggedLayout: React.VFC<Props> = ({
  children,
  isNavBarHidden,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  const [collapsed, setCollapsed] = useState<boolean>(window.innerWidth <= 768);
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    const windowWidth = window.innerWidth;
    setCollapsed(windowWidth <= 768);
    setWidth(windowWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  async function onClickNewFinancingNeed() {
    const result = (await dispatch(getFinancingNeeds(user?.ngoId)))
      .payload as FinancingNeed[];
    if (
      user?.maxNumberOfProjects &&
      result.length + 1 > user?.maxNumberOfProjects
    ) {
      message.error(
        user.maxNumberOfProjects === 1
          ? 'Vous ne pouvez pas créer plus de ' +
              user.maxNumberOfProjects +
              ' projet'
          : 'Vous ne pouvez pas créer plus de ' +
              user.maxNumberOfProjects +
              ' projets',
      );
    } else {
      history.push('/newProject');
    }
  }

  async function onClickNewCallForTender() {
    const result = (await dispatch(getCallForTenderFromDonorId(user?.donorId)))
      .payload as CallForTender[];
    if (
      user?.maxNumberOfProjects &&
      result.length + 1 > user?.maxNumberOfProjects
    ) {
      message.error(
        user.maxNumberOfProjects === 1
          ? 'Vous ne pouvez pas créer plus de ' +
              user.maxNumberOfProjects +
              ' programme de soutien'
          : 'Vous ne pouvez pas créer plus de ' +
              user.maxNumberOfProjects +
              ' programmes de soutien',
      );
    } else {
      if (user?.donorId) {
        history.push(`/donor/${user?.donorId}/saveTenderDonor`);
      }
    }
  }

  function refreshPage() {
    window.location.reload();
    return true;
  }

  const navItems = useMemo<NavItemProps[]>(() => {
    switch (user?.role) {
      case 'contributor':
        return [
          {
            navKey: 'donorsToQualify',
            route: '/',
            icon: <EditOutlined className={classes.icon} />,
            label: <span className={classes.label}>À qualifier</span>,
            onClick: () => isMobile && setCollapsed(true),
          },
          {
            navKey: 'donorsToValidate',
            route: '/donorsToValidate',
            icon: <DatabaseOutlined className={classes.icon} />,
            label: <span className={classes.label}>À valider</span>,
            onClick: () => isMobile && setCollapsed(true),
          },
          {
            navKey: 'validatedDonors',
            route: '/validatedDonors',
            icon: <CheckOutlined className={classes.icon} />,
            label: <span className={classes.label}>Validés</span>,
            onClick: () => isMobile && setCollapsed(true),
          },
          {
            navKey: 'donorsNotRelevant',
            route: '/donorsNotRelevant',
            icon: <DatabaseOutlined className={classes.icon} />,
            label: <span className={classes.label}>Non pertinent</span>,
            onClick: () => isMobile && setCollapsed(true),
          },
          {
            navKey: 'logout',
            icon: <LogoutOutlined className={classes.iconRed} />,
            label: (
              <span className={classes.labelRed}>{t('common.logout')}</span>
            ),
            onClick: () => dispatch(logout()),
          },
        ];
      default:
        return [
          // {
          //   navKey: 'dashboard',
          //   route: '/',
          //   icon: <DashboardOutlined className={classes.icon} />,
          //   label: <span className={classes.label}>Tableau de bord</span>,
          //   onClick: () => isMobile && setCollapsed(true),
          // },
          ...(user?.role !== 'admin' && user?.ngoId
            ? [
                ...(user?.ngoId
                  ? [
                      {
                        navKey: 'title1',
                      },
                    ]
                  : []),
                ...(user?.ngoId
                  ? [
                      {
                        navKey: 'ngo',
                        route: `/ngo/${user?.ngoId}/bypass`,
                        icon: <HomeOutlined className={classes.icon} />,
                        label: (
                          <span className={classes.label}>
                            Mon organisation
                          </span>
                        ),
                        onClick: () => isMobile && setCollapsed(true),
                      },
                    ]
                  : []),
                ...(user?.ngoId
                  ? [
                      {
                        navKey: 'projects',
                        route: '/projects',
                        icon: <FolderOpenOutlined className={classes.icon} />,
                        label: (
                          <span className={classes.label}>Tableau de bord</span>
                        ),
                        onClick: () => isMobile && setCollapsed(true),
                      },
                    ]
                  : []),
                ...(user?.ngoId
                  ? [
                      {
                        navKey: 'followedDonors',
                        route: `/ngo/followedDonors`,
                        icon: <BellOutlined className={classes.icon} />,
                        label: (
                          <span className={classes.label}>Mes abonnements</span>
                        ),
                        onClick: () => isMobile && setCollapsed(true),
                      },
                    ]
                  : []),
                ...(user?.ngoId
                  ? [
                      {
                        navKey: 'premium',
                        route: `/ngo/premium`,
                        icon: <UnlockOutlined className={classes.icon} />,
                        label: <span className={classes.label}>Premium</span>,
                        onClick: () => isMobile && setCollapsed(true),
                      },
                    ]
                  : []),
                ...(user?.ngoId
                  ? [{ navKey: 'divider2', isDivider: false }]
                  : []),
                ...(user?.ngoId
                  ? [
                      {
                        navKey: 'title2',
                      },
                    ]
                  : []),

                ...(user?.ngoId
                  ? [
                      {
                        navKey: 'features',
                        route: '/',
                        icon: <ZoomInOutlined className={classes.icon} />,
                        label: (
                          <span className={classes.label}>Fonctionnalités</span>
                        ),
                        onClick: () =>
                          window.open(
                            'https://sonar.so/fonctionnalites/',
                            '_blank',
                          ),
                      },
                    ]
                  : []),
                ...(user?.ngoId
                  ? [
                      {
                        navKey: 'demo',
                        route: '/',
                        icon: <CalendarOutlined className={classes.icon} />,
                        label: (
                          <span className={classes.label}>
                            Prendre Rendez-vous
                          </span>
                        ),
                        onClick: () =>
                          window.open(
                            'https://sonar.so/prendre-un-rendez-vous/',
                            '_blank',
                          ),
                      },
                    ]
                  : []),
                // ...(user?.ngoId
                //   ? [{ navKey: 'divider2', isDivider: false }]
                //   : []),
                // ...(user?.ngoId
                //   ? [
                //       {
                //         navKey: 'title3',
                //       },
                //     ]
                //   : []),
                ...(user?.ngoId
                  ? [
                      {
                        navKey: 'indispensables',
                        route: '/',
                        icon: <InfoCircleOutlined className={classes.icon} />,
                        label: (
                          <span className={classes.label}>
                            Les Indispensables
                          </span>
                        ),
                        // onClick: () => {},
                        // window.open('https://sonar.so/', '_blank'),
                      },
                    ]
                  : []),
                ...(user?.ngoId
                  ? [{ navKey: 'divider2', isDivider: false }]
                  : []),
                ...(user?.ngoId
                  ? [
                      {
                        navKey: 'newProject',
                        //route: '/newProject',
                        label: (
                          <div className={classes.newProject}>
                            <PlusCircleOutlined
                              style={{ marginBottom: '0.2vw', color: 'blue' }}
                            />
                            <span className={classes.mainLabel}>
                              Nouveau projet
                            </span>
                            <span className={classes.subLabel}>
                              de votre organisation
                            </span>
                          </div>
                        ),
                        onClick: () => onClickNewFinancingNeed(),
                      },
                    ]
                  : []),
                // ...(user?.ngoId
                //   ? [
                //       {
                //         navKey: 'resources',
                //         route: '/',
                //         icon: <FileDoneOutlined className={classes.icon} />,
                //         label: (
                //           <span className={classes.label}>Ressources</span>
                //         ),
                //         onClick: () =>
                //           window.open(
                //             'https://sonar.so/ressources-porteurs-de-projet/',
                //             '_blank',
                //           ),
                //       },
                //     ]
                //   : []),
              ]
            : []),

          // ...(user?.ngoId ? [{ navKey: 'divider3', isDivider: true }] : []),

          ...(user?.role !== 'admin' && user?.donorId
            ? [
                ...(user?.donorId
                  ? [
                      {
                        navKey: 'title1',
                      },
                    ]
                  : []),
                ...(user?.donorId
                  ? [
                      {
                        navKey: 'donor',
                        route: `/donor/${user?.donorId}/bypass`,
                        icon: <HomeOutlined className={classes.icon} />,
                        label: (
                          <span className={classes.label}>
                            Mon organisation
                          </span>
                        ),
                        onClick: () => isMobile && setCollapsed(true),
                      },
                    ]
                  : []),
                ...(user?.donorId
                  ? [
                      {
                        navKey: 'callForTenders',
                        route: '/callForTenders',
                        icon: <FolderOpenOutlined className={classes.icon} />,
                        label: (
                          <span className={classes.label}>Tableau de bord</span>
                        ),
                        onClick: () => isMobile && setCollapsed(true),
                      },
                    ]
                  : []),
                ...(user?.donorId
                  ? [{ navKey: 'divider2', isDivider: false }]
                  : []),
                ...(user?.donorId
                  ? [
                      {
                        navKey: 'title2',
                      },
                    ]
                  : []),

                ...(user?.donorId
                  ? [
                      {
                        navKey: 'takeMeeting',
                        route: '/',
                        icon: <CalendarOutlined className={classes.icon} />,
                        label: (
                          <span className={classes.label}>
                            Prendre rendez-vous
                          </span>
                        ),
                        onClick: () =>
                          window.open(
                            'https://sonar.so/prendre-un-rendez-vous/',
                            '_blank',
                          ),
                      },
                      ...(user?.donorId
                        ? [
                            {
                              navKey: 'featuresDonors',
                              route: '/',
                              icon: <ZoomInOutlined className={classes.icon} />,
                              label: (
                                <span className={classes.label}>
                                  Fonctionnalités
                                </span>
                              ),
                              onClick: () =>
                                window.open(
                                  'https://sonar.so/fonctionnalites-mecenes/',
                                  '_blank',
                                ),
                            },
                          ]
                        : []),
                      ...(user?.donorId
                        ? [{ navKey: 'divider2', isDivider: false }]
                        : []),
                      ...(user?.donorId
                        ? [
                            {
                              navKey: 'newProject',
                              //route: `/donor/${user?.donorId}/saveTenderDonor`,
                              label: (
                                <div className={classes.newProject}>
                                  <PlusCircleOutlined
                                    style={{
                                      marginBottom: '0.2vw',
                                      color: 'blue',
                                    }}
                                  />
                                  <span className={classes.mainLabel}>
                                    Nouveau programme
                                  </span>
                                  <span className={classes.subLabel}>
                                    de votre organisation
                                  </span>
                                </div>
                              ),
                              onClick: () => onClickNewCallForTender(),
                            },
                          ]
                        : []),

                      // ...(user?.donorId
                      //   ? [
                      //       {
                      //         navKey: 'resourcesDonor',
                      //         route: '/',
                      //         icon: (
                      //           <FileDoneOutlined className={classes.icon} />
                      //         ),
                      //         label: (
                      //           <span className={classes.label}>
                      //             Ressources
                      //           </span>
                      //         ),
                      //         onClick: () =>
                      //           window.open(
                      //             'https://sonar.so/ressources-mecenes/',
                      //             '_blank',
                      //           ),
                      //       },
                      //     ]
                      //   : []),
                    ]
                  : []),
              ]
            : []),

          // { navKey: 'divider1', isDivider: true },
          ...(user?.role === 'admin'
            ? [
                // { navKey: 'divider2', isDivider: false },
                {
                  navKey: 'database',
                  route: '/database',
                  icon: <DatabaseOutlined className={classes.icon} />,
                  label: <span className={classes.label}>M. FR en ligne</span>,
                  onClick: () => isMobile && setCollapsed(true),
                },
                {
                  navKey: 'users/ngo',
                  route: '/users/ngo',
                  icon: <TeamOutlined className={classes.icon} />,
                  label: (
                    <span className={classes.label}>PP. Utilisateurs</span>
                  ),
                  onClick: () =>
                    refreshPage() && isMobile && setCollapsed(true),
                },
                {
                  navKey: 'users/donor',
                  route: '/users/donor',
                  icon: <TeamOutlined className={classes.icon} />,
                  label: <span className={classes.label}>M. Utilisateurs</span>,
                  onClick: () =>
                    refreshPage() && isMobile && setCollapsed(true),
                },
                {
                  navKey: 'wachete',
                  route: '/wachete',
                  icon: <LinkOutlined className={classes.icon} />,
                  label: <span className={classes.label}>Wachete</span>,
                  onClick: () => isMobile && setCollapsed(true),
                },
                {
                  navKey: 'statistics/ngo',
                  route: '/statistics/ngo',
                  icon: <LineChartOutlined className={classes.icon} />,
                  label: <span className={classes.label}>PP. Stat</span>,
                  onClick: () =>
                    refreshPage() && isMobile && setCollapsed(true),
                },
                {
                  navKey: 'statistics/donor',
                  route: '/statistics/donor',
                  icon: <LineChartOutlined className={classes.icon} />,
                  label: <span className={classes.label}>M. Stat</span>,
                  onClick: () =>
                    refreshPage() && isMobile && setCollapsed(true),
                },
                {
                  navKey: 'adminNgos',
                  route: '/adminNgos',
                  icon: <DatabaseOutlined className={classes.icon} />,
                  label: (
                    <span className={classes.label}>Porteurs de projets</span>
                  ),
                  onClick: () => isMobile && setCollapsed(true),
                },
                {
                  navKey: 'financing-needs',
                  route: '/financing-needs',
                  icon: <DollarCircleOutlined className={classes.icon} />,
                  label: <span className={classes.label}>Projets</span>,
                  onClick: () => isMobile && setCollapsed(true),
                },
                {
                  navKey: 'donors',
                  route: '/donors',
                  icon: <DatabaseOutlined className={classes.icon} />,
                  label: <span className={classes.label}>Mécènes</span>,
                  onClick: () => isMobile && setCollapsed(true),
                },
                {
                  navKey: 'callForTendersAdmin',
                  route: '/callForTendersAdmin',
                  icon: <DollarCircleOutlined className={classes.icon} />,
                  label: (
                    <span className={classes.label}>Programmes de soutien</span>
                  ),
                  onClick: () => isMobile && setCollapsed(true),
                },
                { navKey: 'dividerAdmin', isDivider: true },
                {
                  navKey: 'contributors',
                  route: '/contributors',
                  icon: <TeamOutlined className={classes.icon} />,
                  label: <span className={classes.label}>Contributeurs</span>,
                  onClick: () => isMobile && setCollapsed(true),
                },
                {
                  navKey: 'donorsToQualify',
                  route: '/donorsToQualify',
                  icon: <DatabaseOutlined className={classes.icon} />,
                  label: <span className={classes.label}>À assigner</span>,
                  onClick: () => isMobile && setCollapsed(true),
                },
                {
                  navKey: 'assignedDonors',
                  route: '/assignedDonors',
                  icon: <DatabaseOutlined className={classes.icon} />,
                  label: <span className={classes.label}>Assignées</span>,
                  onClick: () => isMobile && setCollapsed(true),
                },
                {
                  navKey: 'donorsToValidate',
                  route: '/donorsToValidate',
                  icon: <DatabaseOutlined className={classes.icon} />,
                  label: <span className={classes.label}>À valider</span>,
                  onClick: () => isMobile && setCollapsed(true),
                },
                {
                  navKey: 'donorsNotRelevant',
                  route: '/donorsNotRelevant',
                  icon: <DatabaseOutlined className={classes.icon} />,
                  label: <span className={classes.label}>Non pertinent</span>,
                  onClick: () => isMobile && setCollapsed(true),
                },
                {
                  navKey: 'donorsMergeDuplicates',
                  route: '/donors-merge-duplicates',
                  icon: <BlockOutlined className={classes.icon} />,
                  label: (
                    <span className={classes.label}>Fusion des doublons</span>
                  ),
                  onClick: () => isMobile && setCollapsed(true),
                },
                {
                  navKey: 'ngosMergeDuplicates',
                  route: '/ngos-merge-duplicates',
                  icon: <BlockOutlined className={classes.icon} />,
                  label: (
                    <span className={classes.label}>
                      Fusion des associations
                    </span>
                  ),
                  onClick: () => isMobile && setCollapsed(true),
                },
              ]
            : []),
          // {
          //   navKey: 'services',
          //   route: '/services',
          //   icon: <CustomerServiceOutlined className={classes.icon} />,
          //   label: <span className={classes.label}>Aide à la levée</span>,
          //   onClick: () => isMobile && setCollapsed(true),
          // },
          // TODO: confirm if completely removing settings
          // ...(user?.ngoId
          //   ? [
          //       {
          //         navKey: 'settings',
          //         route: '/settings',
          //         icon: <SettingOutlined className={classes.icon} />,
          //         label: <span className={classes.label}>Réglages</span>,
          //         onClick: () => isMobile && setCollapsed(true),
          //       },
          //     ]
          //   : []),
          // divider
          // { navKey: 'bottomDivider', isDivider: true },
          ...(user?.ngoId ? [{ navKey: 'divider2', isDivider: false }] : []),
          {
            navKey: 'logout',
            icon: <LogoutOutlined className={classes.iconRed} />,
            label: (
              <span className={classes.labelRed}>{t('common.logout')}</span>
            ),
            onClick: () => dispatch(logout()),
          },
        ];
    }
  }, [user]);

  const isMobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  return (
    <Layout style={{ height: '100%', background: 'white' }}>
      {(user?.ngoId || user?.role === 'contributor' || !user?.ngoId) && (
        <Sider
          className={classes.sider}
          collapsedWidth={'0'}
          collapsed={collapsed}
          theme={'light'}
          collapsible
          trigger={null}
          hidden={isNavBarHidden ? true : false}
        >
          {!isMobile ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '5vw',
              }}
            >
              <img
                alt="logo"
                src={logo}
                className={classes.logo}
                onClick={() => history.push('/')}
              />
              <span className={classes.logoText}>sonar</span>
            </div>
          ) : (
            <Typography className={classes.mobileSiderTitle} />
          )}
          <NavBar navItems={navItems} />
        </Sider>
      )}
      <Layout className={classes.siteLayout}>
        {isMobile && (
          <Header style={{ padding: '10px' }}>
            <div className={classes.header}>
              <MenuOutlined
                style={{ color: 'white', fontSize: '1.875vw' }}
                height={'30'}
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              />
              <Image
                src={logo}
                width={30}
                preview={false}
                onClick={() => history.push('/')}
              />
            </div>
          </Header>
        )}
        <Content
          style={{
            boxSizing: 'border-box',
            height: '100%',
            overflow: 'auto',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
