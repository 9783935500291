import { Button, Card, Divider, Radio, RadioChangeEvent } from 'antd';

import React from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';
import { Offer } from '../../slices/financingNeed';
import { Pricing } from '../../pages/Stripe/Payment';
import { useHistory } from 'react-router-dom';
import { Benefit } from './Benefit';
import { BenefitTitle } from './BenefitTitle';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  mainTitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '30px !important',
    lineHeight: '31px !important',
    textAlign: 'center',
    marginBottom: '10px',
  },
  offersBox: {
    width: '557px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-between',
    gap: '20px',
  },
  cardHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: '300',
    fontSize: '1.3vw !important',
    lineHeight: '1 !important',
    textAlign: 'left',
    marginBottom: '1vh !important',
    color: '#5050F6',
  },
  cardPrice: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'baseline',
    gap: '7px',
    width: '100%',
  },
  cardPrice1: {
    fontWeight: '600',
    fontSize: '30px !important',
    lineHeight: '31px !important',
    textAlign: 'left',
    color: '#171717',
  },
  cardPriceCrossed: {
    fontWeight: '600',
    fontSize: '20px !important',
    lineHeight: '21px !important',
    textAlign: 'left',
    color: '#A0ABBB',
    textDecoration: 'line-through',
  },
  cardPrice2: {
    fontFamily: "'Baloo 2'",
    fontWeight: '400',
    fontSize: '12px !important',
    lineHeight: '16px !important',
    textAlign: 'left',
    color: '#A3A3A3',
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
  },
  offerArgBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '11px',
  },
  offerArgText: {
    fontFamily: "'Baloo 2'",
    fontWeight: '400',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    color: '#292929',
  },
  paymentButton: {
    fontWeight: '600',
    fontSize: '18px !important',
    lineHeight: '17.4px !important',
    backgroundColor: theme.palette.primary,
    color: 'white',
    alignContent: 'center',
    height: '64px',
    borderRadius: '5.22px',
    '&:hover': {
      backgroundColor: '#EEF2FF',
    },
  },
  remark: {
    color: 'black',
    fontWeight: '400',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    marginTop: '20px',
  },
  offersTitleComplete: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.7vw !important',
    lineHeight: '1 !important',
    textAlign: 'center',
    marginBottom: '1vh !important',
    textDecoration: 'underline',
    textDecorationColor: '#10B981',
    textUnderlineOffset: '0.5vw',
    textDecorationThickness: '0.2vw',
  },
  title: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.6875vw !important',
    lineHeight: '1 !important',
    margin: '0 0 15px 0 !important',
    textAlign: 'center',
  },
  subtitle: {
    // fontWeight: 'bold',
    fontSize: '1.4vw',
    lineHeight: 1,
    marginBottom: '4.26%',
  },
  discount: {
    fontWeight: 'bold',
    fontSize: 'calc(5px + 0.6vw)',
    lineHeight: 1,
    color: '#ff2255',
  },
  text: {
    fontWeight: 'bold',
    fontSize: 'calc(5px + 0.6vw)',
    lineHeight: 1,
  },
  offersRow: {},
  cardVisibility: {
    borderRadius: '5%',
    backgroundColor: 'white',
    paddingTop: '5vh',
    paddingBottom: '3vh',
  },
  cardComplete: {
    borderRadius: '5%',
    backgroundColor: 'white',
    paddingTop: '5vh',
    paddingBottom: '3vh',
    '&:hover': {
      boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
    },
  },
  table: {
    margin: '0 10vh',
    '& tr': {
      '& td': {
        paddingBottom: '2%',
      },
    },
  },
  radioGroup: {
    '& .ant-radio-button-wrapper': {
      // no border
      border: 'none',
    },
    '& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)':
      {
        color: '#4F46E5 !important',
        background: '#EEF2FF !important',
        borderRadius: '6px',
        border: 'none',
      },
    '& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before':
      {
        backgroundColor: 'transparent !important',
        border: 'none !important',
      },
    '& .ant-radio-button-wrapper:not(:first-child)::before': {
      display: 'none',
    },
  },
  card: {
    '& .ant-card-body': {
      padding: '17.4px !important',
    },
  },
});

interface SubscriptionProps {
  projectId: string;
  type: 'ngo' | 'donor';
  offerCompleteExists: string;
  projectName?: string;
}

export const Offers: React.VFC<SubscriptionProps> = ({
  projectId,
  type,
  offerCompleteExists,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isMonthly, setIsMonthly] = React.useState(
    type === 'ngo' ? 'true' : 'false',
  );

  function renderArgs() {
    if (type === 'ngo') {
      return (
        <>
          <Benefit value="Offre sans engagement de durée. Annulable en 1 clic." />
          <Benefit value="Travaillez sur 10 projets simultanément." />

          <BenefitTitle value="Recherche et prospection" />
          <Benefit value="Identifiez les mécènes* les plus pertinents grâce à nos algorithmes." />
          <Benefit value="Identifiez les programmes de soutien (appels à projets, prix, bourse etc...) les plus pertinents." />

          <BenefitTitle value="Veille automatique" />
          <Benefit value="Soyez notifié des nouveaux mécènes en France." />
          <Benefit value="Soyez notifié des nouveaux programmes de soutien (appels à projets, prix, bourse etc...)." />

          <BenefitTitle value="Gestion de projet" />
          <Benefit value="Suivez l'avancement de vos démarches avec notre outil dédié." />

          <BenefitTitle value="Communauté Sonar" />
          <Benefit value="Bienvenue ! Plus nous serons nombreux ici, plus il nous sera facile de monter des projets." />
        </>
      );
    } else {
      return (
        <>
          <Benefit value="Travaillez sur 10 programmes de soutien (appels à projet, prix, bourse...) simultanément." />
          <Benefit value="Identifiez les associations les plus pertinentes grâce à nos algorithmes." />
          <Benefit value="Soyez notifié par mail des nouvelles assos en France." />
          <Benefit value="Identifiez les appels à projets les plus pertinents." />
          <Benefit value="Soyez notifié par mail des nouveaux appels à projet." />
          <Benefit value="Suivez l'avancement de vos démarches." />
          <Benefit value="Offre sans engagement de durée. Annulable en 1 clic." />
        </>
      );
    }
  }

  function checkoutOffer(annualResource: Pricing, offer: Offer) {
    history.push(
      `/checkout/${projectId}/${annualResource}/${type}/${offer.toString()}/${isMonthly}`,
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.mainTitle}>
        Gagnez du temps et accélérez vos recherches de{' '}
        {type === 'ngo' ? 'mécènes' : 'porteurs de projet'} pour vos{' '}
        {type === 'ngo' ? 'projets' : 'programmes de soutien'} !
      </div>
      <span
        style={{
          color: theme.palette.primary,
          fontSize: '30px',
          lineHeight: '31.5px',
          fontWeight: '600',
        }}
      >
        Offre Premium
      </span>
      {type === 'ngo' && (
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '4px',
          }}
        >
          <Radio.Group
            options={[
              {
                label: (
                  <div
                    style={{
                      display: 'flex',
                      gap: '8px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <>Offre annuelle</>
                    <div
                      style={{
                        backgroundColor: '#ECFDF5',
                        borderRadius: '24px',
                        fontSize: '12px',
                        height: '24px',
                        color: '#10B981',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0 8px',
                      }}
                    >
                      <div>-20%</div>
                    </div>
                  </div>
                ),
                value: 'false',
              },
              { label: 'Offre mensuelle', value: 'true' },
            ]}
            onChange={({ target: { value } }: RadioChangeEvent) => {
              console.log('radio2 checked', value);
              setIsMonthly(value);
            }}
            value={isMonthly}
            optionType="button"
            buttonStyle="solid"
            style={{ marginTop: '0px' }}
            className={classes.radioGroup}
          />
        </div>
      )}
      <Card className={classes.card}>
        <div className={classes.offersBox}>
          <div className={classes.cardHeader}>
            <div
              style={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                color: theme.palette.primary,
              }}
            >
              Offre Premium
            </div>
            {isMonthly === 'true' ? (
              <div className={classes.cardPrice}>
                <div className={classes.cardPrice1}>39€</div>
                <div className={classes.cardPrice2}>/ mois</div>
              </div>
            ) : (
              <div className={classes.cardPrice}>
                <div className={classes.cardPriceCrossed}>468€</div>
                <div className={classes.cardPrice1}>374€</div>
                <div className={classes.cardPrice2}>/ an</div>
              </div>
            )}
          </div>
          <Divider style={{ margin: '0px' }} />
          <div className={classes.cardBody}>
            {renderArgs()}
            <Button
              className={classes.paymentButton}
              onClick={() => checkoutOffer(Pricing.inf100K, Offer.complete)}
              disabled={offerCompleteExists === 'true' ? true : false}
            >
              Activez les fonctionnalités
            </Button>
          </div>
        </div>
      </Card>
      {type === 'ngo' && (
        <div className={classes.remark}>
          *(Fondation reconnue publique, fondation d&apos;entreprise, fondation
          abritante, fondation abritée, fonds de dotation, entreprise)
        </div>
      )}
      {type === 'donor' && (
        <div className={classes.remark}>
          * Pour les entreprises, ressources allouées aux mécénats sur
          l’exercice N-1.
        </div>
      )}
    </div>
  );
};
