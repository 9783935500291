import { DollarOutlined } from '@ant-design/icons';
import { Button, message, Modal, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { fetchCurrentUser } from 'src/slices/auth';
import { theme } from '../constants/theme';
import { apiRequest } from '../helpers/api';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { getFinancingNeeds, Offer } from '../slices/financingNeed';
import { getNgo } from '../slices/ngo';
import { checkHasPaidOffer, isOrganizationPremium } from '../utils/projects';

const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    backgroundImage: `url("../../images/dashboardLogo.png")`,
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  fondations: {
    paddingLeft: '5.59%',
    paddingTop: '4.59%',
    fontWeight: '600',
    fontFamily: "'Baloo 2'",
    fontSize: '2.125vw',
    lineHeight: '1',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '2.3vw !important',
    lineHeight: '1 !important',
    margin: '0 0 4.76% 0 !important',
  },
  cardButtonUnsubscribe: {
    width: '350px',
    height: '100px',
    fontSize: '24px',
    backgroundColor: '#2dc4a8',
    border: '1px solid #2dc4a8',
    color: 'white',
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid #2dc4a8',
      color: '#2dc4a8',
    },
  },
  expirationDate: {
    color: theme.palette.primary,
    fontSize: '0.6125vw !important',
  },
  expirationDateAlt: {
    color: '#000',
    fontSize: '0.6125vw !important',
  },
  buttonNational: {
    minHeight: '4vw',
    background:
      'linear-gradient(to right, rgba(0, 0, 255, 0.2) 0%, rgba(0, 0, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 66.66%, rgba(255, 0, 0, 0.2) 66.66%)',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    color: '#000',
    '&:hover': {
      background:
        'linear-gradient(to right, rgba(0, 0, 255, 0.3) 0%, rgba(0, 0, 255, 0.3) 33.33%, rgba(255, 255, 255, 0.3)' +
        ' 33.33%, rgba(255, 255, 255, 0.3) 66.66%, rgba(255, 0, 0, 0.3) 66.66%)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
    },
  },
});

interface Props {
  setIsNavBarHidden?: (isHidden: boolean) => void;
}

export const PremiumNgo: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);

  const [projectIdToCancelSubscription, setProjectIdToCancelSubscription] =
    useState('');

  const [
    expirationDateToCancelSubscription,
    setExpirationDateToCancelSubscription,
  ] = useState<Date | undefined>(undefined);

  const { financingNeeds, loading } = useAppSelector(
    ({ financingNeed: { financingNeeds, loading } }) => ({
      financingNeeds,
      loading,
    }),
  );

  useEffect(() => {
    if (setIsNavBarHidden) {
      setIsNavBarHidden(false);
    }
    fetchCurrentUser();
    dispatch(getFinancingNeeds(user?.ngoId));
  }, []);

  useEffect(() => {
    if (user?.ngoId) {
      dispatch(getNgo(user.ngoId));
    }
  }, [user?.ngoId]);

  const cancelSubscription = async () => {
    try {
      await apiRequest(
        'DELETE',
        `/payment/subscriptionDatabaseAccess/${projectIdToCancelSubscription}/${Offer.complete}/ngo`,
        undefined,
        {},
      );
    } catch (error) {
      message.error(
        'Une erreur est survenue. Merci de rafraichir la page pour réessayer',
      );
    }
  };

  const isPremium = useMemo(() => {
    return isOrganizationPremium(Object.values(financingNeeds.byId));
  }, [financingNeeds]);

  const paidFinancingNeed = useMemo(() => {
    return Object.values(financingNeeds.byId).find((fn) =>
      checkHasPaidOffer(fn),
    );
  }, [financingNeeds]);

  useEffect(() => {
    if (
      !loading &&
      Object.values(financingNeeds.byId) &&
      Object.values(financingNeeds.byId).length > 0 &&
      !isPremium
    ) {
      history.push(
        `/subscription/${
          Object.values(financingNeeds.byId)[0]._id
        }/${isPremium}`,
      );
    }
  }, [financingNeeds, loading]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text className={classes.fondations} style={{ color: '#5050F9' }}>
          Premium
        </Text>
      </div>
      {paidFinancingNeed && isPremium && (
        <div
          style={{
            marginLeft: '5.59%',
            marginTop: '4.59%',
          }}
        >
          <Button
            type="primary"
            className={classes.cardButtonUnsubscribe}
            style={{ padding: '0.3vw 0 0.3vw 0' }}
            onClick={(e) => {
              e.stopPropagation();
              setProjectIdToCancelSubscription(paidFinancingNeed._id);
              setExpirationDateToCancelSubscription(
                paidFinancingNeed.offers.find((o) => o.offer === Offer.complete)
                  ?.expirationDate || undefined,
              );
              setShowCancelSubscriptionModal(true);
            }}
          >
            <DollarOutlined />
            <span>Gérer mon abonnement</span>
          </Button>
        </div>
      )}
      <Modal
        width={600}
        title={`Voulez-vous annuler votre abonnement à l'offre Nationale ?`}
        visible={showCancelSubscriptionModal}
        onCancel={() => setShowCancelSubscriptionModal(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setShowCancelSubscriptionModal(false)}
            type={'primary'}
          >
            Revenir en arrière
          </Button>,
          <Button
            key="submit"
            type={'primary'}
            danger
            onClick={() => {
              cancelSubscription();
              setShowCancelSubscriptionModal(false);
              alert('Le renouvellement de votre abonnement a bien été annulé.');
            }}
          >
            Annuler mon abonnement
          </Button>,
        ]}
      >
        {expirationDateToCancelSubscription && (
          <Text style={{ fontSize: '18px' }}>
            Votre abonnement prendra fin le :{' '}
            {new Date(expirationDateToCancelSubscription).toLocaleString(
              'fr-FR',
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              },
            )}
          </Text>
        )}
      </Modal>
    </div>
  );
};
