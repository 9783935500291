import React, { useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { NavItem, NavItemProps } from './NavItem';
import { useLocation } from 'react-router';
import { Menu, Popover } from 'antd';

interface NavBarProps {
  navItems: NavItemProps[];
}

const { Divider } = Menu;

const useStyles = createUseStyles({
  icon: {
    color: '#5050F9',
    fontSize: '1.375vw !important',
    marginRight: '6.67% !important',
  },
  label: {
    fontSize: '0.9375vw',
    position: 'relative',
    bottom: '10.53%',
  },
  menu: {
    '& .ant-menu-item-divider': {
      backgroundColor: '#123573',
      margin: '1.25vw',
    },
    '&.ant-menu.ant-menu-light .ant-menu-item-selected': {
      backgroundColor: 'transparent',
      color: 'black',
      fontWeight: '600',
    },
    '&.ant-menu.ant-menu-light .ant-menu-item-active': {
      backgroundColor: 'transparent',
      color: 'black',
      // fontWeight: '500',
    },
    '&.ant-menu.ant-menu-light .ant-menu-item::after': {
      borderRight: 'transparent',
    },
    '& .ant-menu-item': {
      padding: '3.74% 14.29% !important',
      margin: '0px !important',
      lineHeight: '1',
      height: 'auto',
    },
  },
  titleLabel: {
    fontSize: '0.9vw',
    color: '#A6A6A6',
    marginLeft: '14.29%',
  },
  divider: {
    color: '#1230',
    backgroundColor: '#1230 !important',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  topList: {
    flexGrow: 1,
    height: 'auto',
  },
  bottomList: {
    height: 'auto',
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'flex-end',
  },
});

export const NavBar: React.VFC<NavBarProps> = ({ navItems }) => {
  const classes = useStyles();
  const location = useLocation();
  const [navItemsState, setNavItemsState] = React.useState<NavItemProps[]>([]);

  useEffect(() => {
    setNavItemsState(navItems);
  }, [navItems]);

  const currentNavItem = useMemo<NavItemProps | undefined>(
    () =>
      navItemsState.find(
        (navItem) =>
          location.pathname.split('/')[1] == navItem.route?.split('/')[1],
      ),
    [navItemsState, location],
  );

  useEffect(() => {
    if (currentNavItem) {
      navItemsState.find((navItem) => navItem.navKey == currentNavItem?.navKey);
      setNavItemsState((prevState) => {
        const newState = [...prevState];
        const current = newState.find(
          (navItem) => navItem.navKey == currentNavItem?.navKey,
        );
        if (current) {
          current.style = {
            color: 'blue',
          };
          newState.map((navItem) => {
            if (navItem.navKey != current.navKey) {
              navItem.style = {
                color: 'black',
              };
            }
            return navItem;
          });
        }
        return newState;
      });
    }
  }, [currentNavItem]);

  return (
    <Menu
      theme="light"
      mode="inline"
      className={classes.menu}
      selectedKeys={
        currentNavItem?.navKey ? [currentNavItem?.navKey] : undefined
      }
    >
      <div className={classes.topList}>
        {navItemsState.map(({ navKey, isDivider, ...rest }, index) =>
          navKey !== 'logout' && navKey !== 'settings' ? (
            isDivider ? (
              <Divider key={index} className={classes.divider} />
            ) : navKey === 'indispensables' ? (
              <Popover
                placement="bottom"
                key={index}
                content={
                  <>
                    <NavItem
                      key={'generalInterests'}
                      navKey={'generalInterests'}
                      route={'/'}
                      label={
                        <span className={classes.label}>
                          L&apos;intérêt général
                        </span>
                      }
                      onClick={() =>
                        window.open(
                          'https://sonar.so/interet-general/',
                          '_blank',
                        )
                      }
                    />
                    <NavItem
                      key={'fisc'}
                      navKey={'fisc'}
                      route={'/'}
                      label={
                        <span className={classes.label}>Le reçu fiscal</span>
                      }
                      onClick={() =>
                        window.open(
                          'https://sonar.so/le-recu-fiscal/',
                          '_blank',
                        )
                      }
                    />
                    <NavItem
                      key={'fisc2'}
                      navKey={'fisc2'}
                      route={'/'}
                      label={
                        <span className={classes.label}>Le rescrit fiscal</span>
                      }
                      onClick={() =>
                        window.open(
                          'https://sonar.so/le-rescrit-fiscal/',
                          '_blank',
                        )
                      }
                    />
                  </>
                }
              >
                <NavItem key={index} navKey={navKey} {...rest} />
              </Popover>
            ) : navKey === 'title1' ? (
              <div key={index}>
                <div style={{ minHeight: '1vw' }}></div>
                <span className={classes.titleLabel}>Mon espace</span>
                <div style={{ minHeight: '1.5vw' }}></div>
              </div>
            ) : navKey === 'title2' ? (
              <div key={index}>
                <div style={{ minHeight: '1vw' }}></div>
                <span className={classes.titleLabel}>En savoir plus</span>
                <div style={{ minHeight: '1.5vw' }}></div>
              </div>
            ) : (
              // ) : navKey === 'title3' ? (
              //   <>
              //     <div style={{ minHeight: '1vw' }}></div>
              //     <span className={classes.titleLabel}>À votre disposition</span>
              //     <div style={{ minHeight: '1.5vw' }}></div>
              //   </>
              <NavItem key={index} navKey={navKey} {...rest} />
            )
          ) : (
            <div key={index}></div>
          ),
        )}
      </div>
      <div className={classes.bottomList}>
        {navItemsState.map(
          ({ navKey, ...rest }, index) =>
            (navKey === 'logout' || navKey === 'settings') && (
              <NavItem key={index} navKey={navKey} {...rest} />
            ),
        )}
      </div>
    </Menu>
  );
};
