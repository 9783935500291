import {
  EditOutlined,
  LinkedinFilled,
  LinkOutlined,
  MinusCircleFilled,
  MoreOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Dropdown,
  Menu,
  message,
  Tooltip,
  Typography,
} from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import React from 'react';
import { createUseStyles } from 'react-jss';
import '../../index.css';
import { useHistory } from 'react-router-dom';
import ReadMore from 'src/components/ReadMore';
import { Classification } from 'src/slices/classification';
import { FullscreenSpin } from '../../components/FullscreenSpin';
import { TagList } from '../../components/TagList';
import { Ngo } from 'src/slices/ngo';
import { ProjectCards } from '../../containers/ProjectCards';
import { User } from '../../slices/auth';
import { CallForTender } from '../../slices/callForTender';
import { FinancingNeed } from '../../slices/financingNeed';

const { Title, Text, Link } = Typography;

const useStyles = createUseStyles({
  title: {
    width: '60%',
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.6875vw !important',
    lineHeight: '1 !important',
    margin: '0 0 15px 0 !important',
  },
  moreOutlined: {
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    cursor: 'default',
  },
  popover: {
    fontSize: '1.125vw !important',
  },
  firstLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleInformations: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    // alignSelf: 'center',
    lineHeight: '1 !important',
    marginBottom: '4.76% !important',
  },
  fiche: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1vw !important',
    lineHeight: '1 !important',
    marginBottom: '1% !important',
    color: '#5050F9 !important',
  },
  subtitle: {
    marginBottom: '3.62%',
    color: '#00bd9c',
    fontWeight: 'bold',
    fontSize: 'calc(5px + 0.6vw)',
    lineHeight: 1,
  },
  leftColumn: {
    width: '45vw',
    display: 'flex',
    flexDirection: 'column',
    padding: '6.13% 4.98% 8.42%',
    background: 'white',
    borderRadius: '0.3125vw',
    minHeight: '100vh',
    height: '100%',
  },
  titleContainer: {
    position: 'relative',
    width: 'auto',
    height: 'auto',
    display: 'flex',
    marginBottom: '7.14%',
  },
  validateButton: {
    margin: '0 0.5vw 0.5vw 0',
    padding: '0.25vw 0.9375vw',
    fontSize: 'calc(5px + 0.4vw)',
    height: 'fit-content',
  },
  tag: {
    marginTop: '4.76%',
    '& .ant-tag': {
      margin: '0px 1.55% 2.38% 0px',
      borderColor: '#ececf8',
      backgroundColor: '#ececf8',
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      fontSize: 'calc(5px + 0.4vw)',
      lineHeight: 1.5,
    },
    cursor: 'default !important',
  },
  logoImage: {
    width: '17%',
    maxHeight: 'calc(100% + 1.11vh)',
    position: 'absolute',
    top: '-1.11vh',
    right: 0,
  },
  logoImageRelative: {
    width: '17%',
    maxHeight: 'calc(100% + 1.11vh)',
  },
  editButton: {
    margin: 0,
    fontSize: '0.875vw',
    height: '67.61%',
    padding: '0.25vw 0.9375vw',
  },
  displayLineBreak: {
    whiteSpace: 'pre-line',
    marginBottom: '3.716%',
    fontSize: '0.75vw',
    ['@media screen and (max-width: 1200px)']: {
      fontSize: '9px',
    },
    textJustify: 'auto',
    textAlign: 'justify',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  firstContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
    alignItems: 'center',
  },
  link: {
    marginRight: '0.90vw',
    width: 'fit-content',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkIcon: {
    color: '#5050F9',
    fontSize: '1.0625vw',
  },
});

interface Props {
  classification: Classification;
  ngoId: string;
  ngo: Ngo;
  user: User;
  projects: FinancingNeed[];
  callForTender?: CallForTender;
  callForTenders?: CallForTender[];
  onAddNgo: (ngoId: string, callForTenderId: string) => void;
  onRemoveNgo: (ngoId: string, callForTenderId: string) => void;
}

const NgoPageLeftColumn: React.VFC<Props> = ({
  classification,
  ngoId,
  ngo,
  user,
  projects,
  callForTender,
  callForTenders,
  onAddNgo,
  onRemoveNgo,
}) => {
  const history = useHistory();
  const classes = useStyles();

  if (!classification || !ngo) return <FullscreenSpin />;

  const { tags } = classification;

  const renderIconLink = (
    Icon: React.ComponentType<any>,
    url: string,
    color?: string,
  ) => (
    <Link className={classes.link} href={url} target="_blank">
      <Icon className={classes.linkIcon} style={{ color: color }} />
    </Link>
  );

  const renderIconClick = (
    Icon: React.ComponentType<any>,
    onClick?: () => void,
    color?: string,
  ) => (
    <span className={classes.link} onClick={onClick}>
      <Icon className={classes.linkIcon} style={{ color: color }} />
    </span>
  );

  const renderCallForTendersList = (
    callForTendersOptions: CallForTender[],
    ngoId: string,
    add: boolean,
  ) => (
    <>
      {callForTendersOptions.map((cftOption, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            add
              ? onAddNgo(ngoId, cftOption._id)
              : onRemoveNgo(ngoId, cftOption._id);
          }}
        >
          {cftOption.name}
        </Menu.Item>
      ))}
    </>
  );

  const renderAddNgoSubMenu = (
    ngo: Ngo,
    callForTendersOptions: CallForTender[] | undefined,
  ) => {
    return (
      !!callForTendersOptions?.length && (
        <SubMenu title={'Ajouter à un projet'}>
          {renderCallForTendersList(callForTendersOptions, ngo._id, true)}
        </SubMenu>
      )
    );
  };

  const rendeRemoveNgoSubMenu = (
    ngo: Ngo,
    cftOptions: CallForTender[] | undefined,
  ) => {
    return (
      !!cftOptions?.length && (
        <SubMenu title={"Retirer d'un projet"}>
          {renderCallForTendersList(cftOptions, ngo._id, false)}
        </SubMenu>
      )
    );
  };

  const renderAddRemoveCallForTender = () => {
    if (callForTender) {
      return callForTender?.ngos.includes(ngo._id) ||
        !callForTenders?.some((cft) => !cft.ngos.includes(ngo._id))
        ? renderIconClick(
            MinusCircleFilled,
            () => onRemoveNgo(ngo._id, callForTender._id),
            'red',
          )
        : renderIconClick(PlusCircleFilled, () =>
            onAddNgo(ngo._id, callForTender._id),
          );
    } else {
      const callForTendersIncludeNgo = callForTenders?.filter((cft) =>
        cft.ngos.includes(ngo._id),
      );
      const callForTendersDontIncludeNgo = callForTenders?.filter(
        (cft) => !cft.ngos.includes(ngo._id),
      );
      const menu = (
        <Menu>
          {!!callForTendersDontIncludeNgo?.length &&
            !callForTendersIncludeNgo?.length &&
            renderCallForTendersList(
              callForTendersDontIncludeNgo,
              ngo._id,
              true,
            )}
          {!!callForTendersIncludeNgo?.length &&
            !callForTendersDontIncludeNgo?.length &&
            renderCallForTendersList(callForTendersIncludeNgo, ngo._id, false)}
          {!!callForTendersDontIncludeNgo?.length &&
            !!callForTendersIncludeNgo?.length && (
              <>
                {renderAddNgoSubMenu(ngo, callForTendersDontIncludeNgo)}
                {rendeRemoveNgoSubMenu(ngo, callForTendersIncludeNgo)}
              </>
            )}
        </Menu>
      );
      return (
        <Dropdown overlay={menu}>
          {!callForTenders?.some((cft) => !cft.ngos.includes(ngo._id))
            ? renderIconClick(MinusCircleFilled, undefined, 'red')
            : renderIconClick(PlusCircleFilled)}
        </Dropdown>
      );
    }
  };

  const menuMoreOutlined = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push(`/ngo/${ngoId}/edit`);
        }}
        icon={<EditOutlined style={{ color: 'blue' }} />}
      >
        {'Modifier'}
      </Menu.Item>
    </Menu>
  );

  const onClickNewProject = () => {
    if (user?.role === 'admin') {
      history.push('/newProject');
    } else {
      if (
        user?.maxNumberOfProjects !== undefined &&
        user?.maxNumberOfProjects !== null &&
        projects.length + 1 > user?.maxNumberOfProjects
      ) {
        message.error(
          user.maxNumberOfProjects === 1
            ? 'Vous ne pouvez pas créer plus de ' +
                user.maxNumberOfProjects +
                ' projet'
            : 'Vous ne pouvez pas créer plus de ' +
                user.maxNumberOfProjects +
                ' projets',
        );
      } else {
        history.push('/newProject');
      }
    }
  };

  return (
    <div className={classes.leftColumn}>
      <Title className={classes.fiche}>Fiche porteur de projets</Title>

      <div className={classes.titleContainer}>
        <div style={{ marginBottom: 'auto', marginTop: 'auto', width: '100%' }}>
          <div className={classes.firstLine}>
            <Title className={classes.title} style={{ maxWidth: '80%' }}>
              {ngo.name}
            </Title>
            {ngo.logoUrl && (
              <img
                src={ngo.logoUrl}
                className={classes.logoImageRelative}
                alt="logo"
              />
            )}

            {(ngo._id === user.ngoId || user.role === 'admin') && (
              <Dropdown
                overlay={menuMoreOutlined}
                placement={'bottomCenter'}
                trigger={['click']}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <MoreOutlined
                    // onClick={() => history.push(`/ngo/${ngoId}/edit`)}
                    style={{ color: '#5050f9' }}
                    className={classes.moreOutlined}
                  />
                </a>
              </Dropdown>
            )}
          </div>
          {(!!ngo.websiteUrl || !!ngo.linkedinUrl) && (
            <div className={classes.firstContainer}>
              {ngo.websiteUrl && renderIconLink(LinkOutlined, ngo.websiteUrl)}
              {ngo.linkedinUrl &&
                renderIconLink(LinkedinFilled, ngo.linkedinUrl)}
            </div>
          )}
          {user?.donorId && (
            <Tooltip
              placement="top"
              title={<span>Ajouter / Supprimer de votre tableau de suivi</span>}
            >
              {renderAddRemoveCallForTender()}
            </Tooltip>
          )}
          {(ngo._id === user.ngoId || user.role === 'admin') && (
            <Button
              className={classes.validateButton}
              onClick={onClickNewProject}
              type="dashed"
              icon={<PlusCircleOutlined />}
            >
              Ajouter un projet
            </Button>
          )}

          {/*{(ngo.admins.includes(user._id) || user.role === 'admin') && (*/}
          {/*  <Button*/}
          {/*    className={classes.editButton}*/}
          {/*    type="dashed"*/}
          {/*    onClick={() => history.push(`/ngo/${ngoId}/edit`)}*/}
          {/*    size="small"*/}
          {/*  >*/}
          {/*    Modifier*/}
          {/*  </Button>*/}
          {/*)}*/}
          {ngo.tags && (
            <div className={classes.tag}>
              <TagList
                elementIds={ngo.tags}
                collection={tags}
                color="default"
                collectionName="tags"
                highlightedIds={callForTender?.tags ?? []}
                maxElements={10}
              />
            </div>
          )}
        </div>
      </div>
      {ngo.bannerImageUrl && (
        <Avatar
          icon={<img src={ngo.bannerImageUrl} alt="banner" />}
          className={classes.logoImage}
        />
      )}
      {ngo.description && (
        <>
          <Text className={classes.subtitle}>Description</Text>
          <ReadMore
            description={ngo.description}
            maximumNumberOfCharacters={1267}
          />
        </>
      )}
      {/* {ngo.object && (
        <>
          <Text className={classes.subtitle}>Objet</Text>
          <Text className={classes.displayLineBreak}>{ngo.object}</Text>
        </>
      )} */}
      {(user?.role === 'admin' || user?.ngoId === ngoId) &&
        !!projects.length && (
          <>
            <Text className={classes.subtitle}>Projets</Text>
            <ProjectCards projects={projects} />
          </>
        )}
      {!(user?.role === 'admin' || user?.ngoId === ngoId) &&
        !!projects.filter((c) => c.published).length && (
          <>
            <Text className={classes.subtitle}>Projets</Text>
            <ProjectCards projects={projects.filter((c) => c.published)} />
          </>
        )}
    </div>
  );
};

export default NgoPageLeftColumn;
