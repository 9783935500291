import { Button, Input, List, Modal } from 'antd';
import Tag from 'antd/es/tag';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FullscreenSpin } from '../../components/FullscreenSpin';
import { ArrowRightOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { CallForTender, reportCFTProblem } from 'src/slices/callForTender';
import Text from 'antd/lib/typography/Text';
import { OddImages } from '../../components/OddImages';
import { PlacesModal } from '../../components/PlacesModal';
import { TagListForDonorAndNgoPages } from '../../components/TagListForNgoAndDonorPages';
import { Classification, Place } from '../../slices/classification';
import { useAppDispatch } from 'src/hooks/store';

const { TextArea } = Input;

const useStyles = createUseStyles({
  socialsContainer: {
    display: 'flex',
    marginLeft: 'auto',
    marginTop: '2.18%',
  },
  subtitle: {
    color: '#00bd9c',
    fontWeight: 'bold',
    fontSize: '0.75vw',
    lineHeight: 1,
  },
  rightColumn: {
    width: '31.57%',
    boxSizing: 'border-box',
    margin: '0 0 0 3.83%',
    '& .ant-list-item-meta-title': {
      marginBottom: '0px',
    },
    '& .ant-list-item-meta-title, .ant-list-item-meta-description': {
      lineHeight: 1,
    },
  },
  dateContainer: {
    display: 'flex',
    marginTop: '6px',
  },
  dateElement: {
    marginRight: '6px',
    fontSize: '0.75vw',
    color: 'black',
    lineHeight: 1,
  },
  rightColumnElement: {
    display: 'flex',
    flexDirection: 'column',
    padding: '9.71% 6.59% 10.68% 6.8%',
    marginBottom: '3.3vh',
    background: 'white',
    borderRadius: '0.3125vw',
  },
  rightColumnLastElement: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10.58% 7.28% 6.16% 7.758%',
    background: 'white',
    borderRadius: '0.3125vw',
    position: 'relative',
    '& .ant-list-item-meta-content': {
      marginTop: 'auto',
      marginBottom: 'auto',
      '& .ant-list-item-meta-description': {
        color: 'black',
        fontSize: '0.75vw',
      },
    },
    '& .ant-list-item-meta-avatar': {
      marginRight: '2.87%',
    },
    '& .ant-avatar': {
      height: 'auto',
      width: 'auto',
      lineHeight: 1,
    },
  },
  link: {
    color: '#00bd9c',
    fontSize: '0.75vw',
  },
  avatar: {
    '&.ant-avatar': {
      backgroundColor: 'transparent',
    },
  },
  informationsIcon: {
    color: '#00bd9c',
    fontSize: '1.0625vw',
  },
  socialsIcon: {
    color: '#00bd9c',
    margin: '0 0.3125vw 0 0',
    fontSize: '1.0625vw',
  },
  problemButton: {
    margin: '4vw 0 0 0',
    padding: '0.25vw 0.9375vw',
    fontSize: '0.75vw',
    height: 'fit-content',
    borderColor: 'green',
    ['@media screen and (max-width: 445px)']: {
      whiteSpace: 'unset',
    },
  },
  problemButtonPosition: {
    position: 'relative',
    marginTop: '-4.5vw',
    marginBottom: '1.5vw',
    marginLeft: '4.5vw',
  },
  tag: {
    '& .ant-tag': {
      margin: '0px 0.625vw 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      fontSize: '0.75vw',
    },
  },
  status: {
    marginTop: '4px',
    fontSize: '0.75vw',
  },
  button: {
    fontSize: '0.75vw',
  },
});

interface Props {
  callForTender: CallForTender;
  classification: Classification;
  // ngos: NgoName[];
}

const statusTranslation = [
  'Programme de soutien en cours',
  'Programme de soutien clos',
  'Programme de soutien permanent',
  'Programme de soutien à venir',
];
const status = ['open', 'closed', 'permanent', 'coming'];

const CallForTenderRightColumn: React.VFC<Props> = ({
  callForTender,
  classification,
  // ngos,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [visible, setVisible] = React.useState(false);
  const [editedProblem, setEditedProblem] = React.useState<string>('');

  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const {
    donationTypes,
    activityDomains,
    targetPopulations,
    places,
    sustainableDevelopmentGoals,
  } = classification;

  if (!callForTender) return <FullscreenSpin />;

  const onEditProblem = (value: string) => {
    setEditedProblem(value);
  };

  const onSubmitProblem = () => {
    if (editedProblem) {
      dispatch(
        reportCFTProblem({
          _id: callForTender._id,
          name: callForTender.name,
          problemDescription: editedProblem,
        }),
      );
      setVisible(false);
    }
    setEditedProblem('');
  };

  const renderDate = (
    openingDate: Date | undefined,
    closingDate: Date | undefined,
  ) => {
    const newOpeningDate = openingDate ? new Date(openingDate) : undefined;
    const newClosingDate = closingDate ? new Date(closingDate) : undefined;
    return (
      <div className={classes.dateContainer}>
        {newOpeningDate && (
          <div className={classes.dateElement}>
            {new Intl.DateTimeFormat('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }).format(newOpeningDate)}
          </div>
        )}
        <ArrowRightOutlined className={classes.dateElement} />
        {newClosingDate && (
          <div className={classes.dateElement}>
            {new Intl.DateTimeFormat('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }).format(newClosingDate)}
          </div>
        )}
      </div>
    );
  };

  const renderStatus = () => {
    const statusToPrint = status.findIndex(
      (status) => status === callForTender?.status,
    );
    return (
      <div className={classes.status}>
        <Text>{statusTranslation[statusToPrint]}</Text>
      </div>
    );
  };

  const [countries, setCountries] = useState<Place[]>([]);
  const [france, setFrance] = useState<Place[]>([]);

  useEffect(() => {
    if (places) {
      setCountries(
        places
          .filter((place) => place.countries && place.countries.length)
          .sort((a, b) => a.name.localeCompare(b.name)) as Place[],
      );
      setFrance(
        places
          .filter((place) => place.departments && place.departments.length)
          .sort((a, b) =>
            a.name.includes('DROM') ||
            a.name.includes("Collectivités d'Outre-Mer")
              ? 1
              : a.name.localeCompare(b.name),
          ) as Place[],
      );
    }
  }, [places]);

  function renderFrance(depIds: string[]): string | undefined {
    if (!depIds || depIds.length === 0) return undefined;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    if (isFranceComplete) {
      return 'France';
    }
    return undefined;
  }

  function renderDepartments(depIds: string[]): string[] {
    if (!depIds || depIds.length === 0) return [];
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return results.length > 0 ? results : [];
    // return results.length > 0
    //   ? results.map((department, index) => (
    //       <Tag key={index} className={classes.tag}>
    //         {department}
    //         {', '}
    //       </Tag>
    //     ))
    //   : [];
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return [];
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return results.length > 0 ? results : [];
    // return results.length > 0
    //   ? results.map((currentCountry, index) => (
    //       <Tag key={index} className={classes.tag}>
    //         {currentCountry},{' '}
    //       </Tag>
    //     ))
    //   : [];
  }

  function renderPlaces(placeIds: string[]): JSX.Element[] {
    if (!placeIds || placeIds.length === 0) return [];
    let results = [] as string[];
    if (renderFrance(placeIds)) {
      const isFrance = renderFrance(placeIds);
      if (isFrance) {
        results = results.concat([isFrance]);
      }
    } else {
      if (renderDepartments(placeIds)) {
        results = results.concat(renderDepartments(placeIds));
      }
    }
    if (renderCountries(placeIds)) {
      results = results.concat(renderCountries(placeIds));
    }
    return results.length > 0
      ? results.map((department, index) =>
          index !== results.length - 1 ? (
            <Tag key={index} className={classes.tag}>
              {department}
              {', '}
            </Tag>
          ) : (
            <Tag key={index} className={classes.tag}>
              {department}
            </Tag>
          ),
        )
      : [];
  }

  const callForTenderCharacteristic = [
    {
      title: <Text className={classes.subtitle}>Statut</Text>,
      description: renderStatus(),
      valid: callForTender.status,
    },
    {
      title: (
        <Text className={classes.subtitle}>
          Modalités du programme de soutien
        </Text>
      ),
      description:
        callForTender.modusOperandi &&
        callForTender.modusOperandi.map((modus) => (
          <div className={classes.tag} key={modus}>
            <Tag>{modus}</Tag>
          </div>
        )),
      valid:
        callForTender.modusOperandi && callForTender.modusOperandi.length > 0,
    },
    {
      title: <Text className={classes.subtitle}>Date</Text>,
      description: renderDate(
        callForTender?.openingDate,
        callForTender?.closingDate,
      ),
      valid: callForTender.openingDate || callForTender.closingDate,
    },
    {
      title: <Text className={classes.subtitle}>Type de mécénat</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={callForTender.donationTypes ?? []}
          collection={donationTypes}
          color="default"
          collectionName="donationTypes"
        />
      ),
      valid:
        callForTender.donationTypes && callForTender.donationTypes.length > 0,
    },
    {
      title: (
        <Text className={classes.subtitle}>Domaines d&apos;intervention</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={callForTender.activityDomains ?? []}
          collection={activityDomains}
          color="default"
          collectionName="activityDomains"
        />
      ),
      valid: callForTender.activityDomains,
    },
    {
      title: (
        <Text className={classes.subtitle}>
          Objectifs du développement durable
        </Text>
      ),
      description: (
        <OddImages
          sustainableDevelopmentGoals={sustainableDevelopmentGoals}
          oddIds={callForTender?.sustainableDevelopmentGoals || []}
        />
      ),
      valid: callForTender.sustainableDevelopmentGoals,
    },
    {
      title: <Text className={classes.subtitle}>Bénéficiaires cibles</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={callForTender.targetPopulations ?? []}
          collection={targetPopulations}
          color="default"
          collectionName="targetPopulations"
        />
      ),
      valid: callForTender.targetPopulations,
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <>
          {renderPlaces(callForTender.places).slice(0, 10)}
          {renderPlaces(callForTender.places).length > 10 && (
            <Button
              size={'small'}
              type="link"
              onClick={() => setShowPlacesModal(true)}
            >
              Voir Tout
            </Button>
          )}
        </>
      ),
      valid: callForTender.places || [],
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={
            callForTender.roadAndNumber &&
            callForTender.city &&
            callForTender.zipcode
              ? [callForTender.roadAndNumber]
              : []
          }
          collection={
            callForTender.roadAndNumber &&
            callForTender.city &&
            callForTender.zipcode
              ? [
                  {
                    _id: callForTender.roadAndNumber,
                    name: `${callForTender.roadAndNumber}, ${callForTender.city}, ${callForTender.zipcode}`,
                  },
                ]
              : []
          }
          color="default"
          collectionName="uniqueCustomAddress"
        />
      ),
      valid:
        callForTender.roadAndNumber &&
        callForTender.city &&
        callForTender.zipcode,
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={
            callForTender.uniqueZipcode ? [callForTender.uniqueZipcode] : []
          }
          collection={
            callForTender.uniqueZipcode
              ? [
                  {
                    _id: callForTender.uniqueZipcode,
                    name: `${callForTender.uniqueZipcode}, ${callForTender.city}`,
                  },
                ]
              : []
          }
          color="default"
          collectionName="uniqueZipcode"
        />
      ),
      valid: callForTender.uniqueZipcode ? [callForTender.uniqueZipcode] : [],
    },
  ];

  // const callForTenderInformations = [
  //   {
  //     title: <Text className={classes.subtitle}>{'Lauréats'}</Text>,
  //     description:
  //       callForTender.fundedNgos &&
  //       callForTender.fundedNgos.map((ngoId) => (
  //         <Text key={ngoId} className={classes.tag}>
  //           {ngos?.find((n) => n._id === ngoId)?.name},&nbsp;
  //         </Text>
  //       )),
  //     valid: callForTender.fundedNgos && callForTender.fundedNgos.length > 0,
  //   },
  // ];

  useEffect(() => {
    console.log(callForTender.fundedNgos);
  }, [callForTender.fundedNgos]);

  const renderReportProblem = (
    onChange: (value: string) => void,
    onSubmit: () => void,
    value: string,
  ) => (
    <div>
      <Button
        className={classes.problemButton}
        type="dashed"
        onClick={() => setVisible(true)}
        icon={<ExclamationCircleFilled style={{ color: 'green' }} />}
      >
        Signaler une anomalie avec cette fiche
      </Button>

      <Modal
        visible={visible}
        title="Signalement d'une fiche programme de soutien"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisible(false);
              setEditedProblem('');
            }}
          >
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={() => onSubmit()}>
            Valider
          </Button>,
        ]}
      >
        <TextArea
          placeholder="Merci de préciser le sujet avec la fiche de ce programme de soutien"
          rows={4}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
      </Modal>
    </div>
  );

  return (
    <div className={classes.rightColumn}>
      <div className={classes.rightColumnElement}>
        <div className={classes.problemButtonPosition}>
          {renderReportProblem(onEditProblem, onSubmitProblem, editedProblem)}
        </div>
        <List
          grid={{ gutter: 0, column: 1 }}
          dataSource={callForTenderCharacteristic}
          className={classes.tag}
          split={false}
          renderItem={(item) =>
            (Array.isArray(item.valid)
              ? item.valid?.length !== 0
              : !!item.valid) && (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )
          }
        />
      </div>
      {/*<div className={classes.rightColumnLastElement}>*/}
      {/*  <List*/}
      {/*    grid={{ gutter: 0, column: 1 }}*/}
      {/*    dataSource={callForTenderInformations}*/}
      {/*    className={classes.tag}*/}
      {/*    split={false}*/}
      {/*    renderItem={(item) =>*/}
      {/*      (Array.isArray(item.valid)*/}
      {/*        ? item.valid?.length !== 0*/}
      {/*        : !!item.valid) && (*/}
      {/*        <List.Item>*/}
      {/*          <List.Item.Meta*/}
      {/*            title={item.title}*/}
      {/*            description={item.description}*/}
      {/*          />*/}
      {/*        </List.Item>*/}
      {/*      )*/}
      {/*    }*/}
      {/*  />*/}
      {/*</div>*/}
      <PlacesModal
        ngoPlaces={
          callForTender.places && callForTender.places.length > 0
            ? callForTender.places
            : []
        }
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
      />
    </div>
  );
};

export default CallForTenderRightColumn;
