import { Avatar, Button, Input, List, Modal, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { getUsers } from 'src/slices/auth';
import { FullscreenSpin } from '../../components/FullscreenSpin';
import { OddImages } from '../../components/OddImages';
import { PlacesModal } from '../../components/PlacesModal';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import {
  ClockCircleOutlined,
  EnvironmentOutlined,
  ExclamationCircleFilled,
  FacebookFilled,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { InstagramFilled } from '@ant-design/icons';
import { TwitterSquareFilled } from '@ant-design/icons';
import { Classification, Place } from '../../slices/classification';
import { Ngo, reportNGOProblem } from 'src/slices/ngo';
import { TagListForDonorAndNgoPages } from 'src/components/TagListForNgoAndDonorPages';
import { getDonor } from '../../slices/donor';

const { Text } = Typography;

const { TextArea } = Input;

const useStyles = createUseStyles({
  socialsContainer: {
    display: 'flex',
    marginLeft: 'auto',
    marginTop: '2.18%',
  },
  problemButton: {
    margin: '4vw 0 0 0',
    padding: '0.25vw 0.9375vw',
    fontSize: '0.75vw',
    height: 'fit-content',
    borderColor: 'green',
    ['@media screen and (max-width: 445px)']: {
      whiteSpace: 'unset',
    },
  },
  problemButtonPosition: {
    position: 'relative',
    marginTop: '-4.5vw',
    marginBottom: '1.5vw',
    marginLeft: '4.5vw',
  },
  subtitle: {
    color: '#00bd9c',
    fontWeight: 'bold',
    fontSize: 'calc(5px + 0.6vw)',
    lineHeight: 1,
  },
  description: {
    fontSize: 'calc(5px + 0.4vw)',
  },
  descriptionText: {
    fontSize: 'calc(5px + 0.4vw)',
    lineHeight: 1.3,
  },
  donorName: {
    fontSize: 'calc(5px + 0.4vw)',
    lineHeight: 1.3,
  },
  separator: {
    textDecoration: 'none !important',
  },
  rightColumn: {
    width: '26.5vw',
    boxSizing: 'border-box',
    margin: '0 0 0 3.83%',
    '& .ant-list': {
      fontSize: '0.875vw',
    },
    '& .ant-list-item-meta-title': {
      marginBottom: '0px',
    },
    '& .ant-list-item-meta-title, .ant-list-item-meta-description': {
      lineHeight: 1,
      fontSize: '0.875vw',
    },
    minHeight: '100vh',
    height: '100%',
  },
  rightColumnElement: {
    display: 'flex',
    flexDirection: 'column',
    padding: '9.59% 8.94% 3.5%',
    marginBottom: '3.3vh',
    background: 'white',
    borderRadius: '0.3125vw',
  },
  rightSecondColumnElement: {
    padding: '7.59% 8.94% 7.59%',
    marginBottom: '3.3vh',
    background: 'white',
    borderRadius: '0.3125vw',
  },
  rightColumnLastElement: {
    display: 'flex',
    flexDirection: 'column',
    padding: '9.59% 8.94% 6.59%',
    background: 'white',
    borderRadius: '0.3125vw',
    position: 'relative',
    wordBreak: 'break-word',
    '& .ant-list-item-meta-content': {
      marginTop: 'auto',
      marginBottom: 'auto',
      '& .ant-list-item-meta-description': {
        color: 'black',
        fontSize: 'calc(5px + 0.4vw)',
      },
    },
    '& .ant-list-item-meta-avatar': {
      marginRight: '2.87%',
    },
    '& .ant-avatar': {
      height: 'auto',
      width: 'auto',
      lineHeight: 1,
      fontSize: '1.125vw',
    },
  },
  link: {
    color: '#5050F9 !important',
  },
  avatar: {
    '&.ant-avatar': {
      backgroundColor: 'transparent',
    },
  },
  informationsIcon: {
    color: '#00bd9c',
    fontSize: '1.0625vw',
  },
  socialsIcon: {
    color: '#5050F9',
    margin: '0 0.3125vw 0 0',
    fontSize: '1.0625vw',
  },
  tag: {
    '& .ant-tag': {
      margin: '0px 6px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      fontSize: 'calc(5px + 0.4vw)',
      lineHeight: 1.5,
    },
  },
  member: {
    fontSize: '0.75vw',
    ['@media screen and (max-width: 1000px)']: {
      fontSize: '7.5px',
    },
  },
  activityZone: {
    fontSize: '0.75vw',
    ['@media screen and (max-width: 1000px)']: {
      fontSize: '7.5px',
    },
  },
});

interface Props {
  classification: Classification;
  ngo: Ngo;
}

const NgoPageRightColumn: React.VFC<Props> = ({ classification, ngo }) => {
  const dispatch = useAppDispatch();

  const [visible, setVisible] = React.useState(false);
  const [editedProblem, setEditedProblem] = React.useState<string>('');

  const classes = useStyles();
  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const { donors } = useAppSelector(({ donor }) => ({
    donors: donor.donors,
  }));

  useEffect(() => {
    // iterate through unverifiedDonord and dispatch getDonor donorId
    ngo.unverifiedDonors.forEach((donorId) => {
      dispatch(getDonor(donorId));
    });
  }, [ngo]);

  useEffect(() => {
    if (ngo?.admins) {
      dispatch(
        getUsers(/*{
          usersIds: ngo.admins,
        }*/),
      );
    }
  }, [ngo?.admins]);

  if (!classification || !ngo) return <FullscreenSpin />;

  const onEditProblem = (value: string) => {
    setEditedProblem(value);
  };

  const onSubmitProblem = () => {
    if (editedProblem) {
      dispatch(
        reportNGOProblem({
          _id: ngo._id,
          name: ngo.name,
          problemDescription: editedProblem,
        }),
      );
      setVisible(false);
    }
    setEditedProblem('');
  };

  const {
    sustainableDevelopmentGoals,
    targetPopulations,
    activityDomains,
    // activityZones,
    places,
  } = classification;
  const [countries, setCountries] = useState<Place[]>([]);
  const [france, setFrance] = useState<Place[]>([]);

  useEffect(() => {
    if (places) {
      setCountries(
        places
          .filter((place) => place.countries && place.countries.length)
          .sort((a, b) => a.name.localeCompare(b.name)) as Place[],
      );
      setFrance(
        places
          .filter((place) => place.departments && place.departments.length)
          .sort((a, b) =>
            a.name.includes('DROM') ||
            a.name.includes("Collectivités d'Outre-Mer")
              ? 1
              : a.name.localeCompare(b.name),
          ) as Place[],
      );
    }
  }, [places]);

  const renderMembers = () =>
    ngo.personals.map(
      (personnel: {
        lastName: React.Key | null | undefined;
        firstName: any;
      }) => {
        return (
          <Typography
            key={personnel.lastName}
            className={classes.member}
          >{`${personnel.firstName} ${personnel.lastName}`}</Typography>
        );
      },
    );

  const handleClickSocials = (url: string | undefined) => {
    url && window.open(url);
  };

  const renderSocials = () => {
    return (
      <>
        <div className={classes.socialsContainer}>
          {ngo.facebookUrl && (
            <FacebookFilled
              onClick={() => handleClickSocials(ngo.facebookUrl)}
              className={classes.socialsIcon}
            />
          )}
          {ngo.twitterUrl && (
            <TwitterSquareFilled
              className={classes.socialsIcon}
              onClick={() => handleClickSocials(ngo.twitterUrl)}
            />
          )}
          {ngo.instagramUrl && (
            <InstagramFilled
              className={classes.socialsIcon}
              onClick={() => handleClickSocials(ngo.instagramUrl)}
            />
          )}
        </div>
      </>
    );
  };

  function renderFrance(depIds: string[]): string | undefined {
    if (!depIds || depIds.length === 0) return undefined;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    if (isFranceComplete) {
      return 'France';
    }
    return undefined;
  }

  function renderDepartments(depIds: string[]): string[] {
    if (!depIds || depIds.length === 0) return [];
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return results.length > 0 ? results : [];
    // return results.length > 0
    //   ? results.map((department, index) => (
    //       <Tag key={index} className={classes.tag}>
    //         {department}
    //         {', '}
    //       </Tag>
    //     ))
    //   : [];
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return [];
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return results.length > 0 ? results : [];
    // return results.length > 0
    //   ? results.map((currentCountry, index) => (
    //       <Tag key={index} className={classes.tag}>
    //         {currentCountry},{' '}
    //       </Tag>
    //     ))
    //   : [];
  }

  function renderPlaces(placeIds: string[]): JSX.Element[] {
    if (!placeIds || placeIds.length === 0) return [];
    let results = [] as string[];
    if (renderFrance(placeIds)) {
      const isFrance = renderFrance(placeIds);
      if (isFrance) {
        results = results.concat([isFrance]);
      }
    } else {
      if (renderDepartments(placeIds)) {
        results = results.concat(renderDepartments(placeIds));
      }
    }
    if (renderCountries(placeIds)) {
      results = results.concat(renderCountries(placeIds));
    }
    return results.length > 0
      ? results.map((department, index) =>
          index !== results.length - 1 ? (
            <Tag key={index} className={classes.tag}>
              {department}
              {', '}
            </Tag>
          ) : (
            <Tag key={index} className={classes.tag}>
              {department}
            </Tag>
          ),
        )
      : [];
  }

  const ngoCharacteristics = [
    {
      title: <Text className={classes.subtitle}>Statut</Text>,
      description: (
        <Text className={classes.descriptionText}>{ngo.status}</Text>
      ),
      valid: true,
    },
    {
      title: (
        <Text className={classes.subtitle}>Domaines d&apos;intervention</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={ngo.activityDomains}
          collection={activityDomains}
          color="default"
          collectionName="activityDomains"
        />
      ),
      valid: ngo.activityDomains,
    },
    {
      title: <Text className={classes.subtitle}>Bénéficiaires cibles</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={ngo.targetPopulations}
          collection={targetPopulations}
          color="default"
          collectionName="targetPopulations"
        />
      ),
      valid: ngo.targetPopulations,
    },
    // {
    //   title: (
    //     <Text className={classes.subtitle}>Zones d&apos;intervention</Text>
    //   ),
    //   description: (
    //     <TagListForDonorAndNgoPages
    //       elementIds={ngo.activityZone ? ngo.activityZone : []}
    //       collection={activityZones}
    //       color="default"
    //       collectionName="activityZones"
    //     />
    //   ),
    //   valid: ngo.activityZone ? ngo.activityZone : [],
    // },
    // {
    //   title: (
    //     <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
    //   ),
    //   description: (
    //     <>
    //       <PlaceListForDonorAndNgoPages
    //         elementIds={ngo.places ? ngo.places.slice(0, 10) : []}
    //         collection={places}
    //         color="default"
    //         collectionName="places"
    //       />
    //       {ngo.places.length > 10 && (
    //         <Button
    //           size={'small'}
    //           type="link"
    //           onClick={() => setShowPlacesModal(true)}
    //         >
    //           Voir Tout
    //         </Button>
    //       )}
    //     </>
    //   ),
    //   valid: ngo.places || [],
    // },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <>
          {renderPlaces(ngo.places).slice(0, 10)}
          {renderPlaces(ngo.places).length > 10 && (
            <Button
              size={'small'}
              type="link"
              onClick={() => setShowPlacesModal(true)}
            >
              Voir Tout
            </Button>
          )}
        </>
      ),
      valid: ngo.places || [],
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={
            ngo.roadAndNumber && ngo.city && ngo.zipcode
              ? [ngo.roadAndNumber]
              : []
          }
          collection={
            ngo.roadAndNumber && ngo.city && ngo.zipcode
              ? [
                  {
                    _id: ngo.roadAndNumber,
                    name: `${ngo.roadAndNumber}, ${ngo.city}, ${ngo.zipcode}`,
                  },
                ]
              : []
          }
          color="default"
          collectionName="uniqueCustomAddress"
        />
      ),
      valid: ngo.roadAndNumber && ngo.city && ngo.zipcode,
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={ngo.uniqueZipcode ? [ngo.uniqueZipcode] : []}
          collection={
            ngo.uniqueZipcode
              ? [
                  {
                    _id: ngo.uniqueZipcode,
                    name: `${ngo.uniqueZipcode}, ${ngo.city}`,
                  },
                ]
              : []
          }
          color="default"
          collectionName="uniqueZipcode"
        />
      ),
      valid: ngo.uniqueZipcode ? [ngo.uniqueZipcode] : [],
    },
    {
      title: (
        <Text className={classes.subtitle}>
          Objectifs du développement durable
        </Text>
      ),
      description: (
        <OddImages
          sustainableDevelopmentGoals={sustainableDevelopmentGoals}
          oddIds={ngo?.sustainableDevelopmentGoals || []}
        />
      ),
      valid: ngo.sustainableDevelopmentGoals,
    },
    {
      title: <Text className={classes.subtitle}>{'Mécènes'}</Text>,
      description:
        ngo.unverifiedDonors &&
        ngo.unverifiedDonors.length > 0 &&
        donors &&
        donors.byId[ngo.unverifiedDonors[0]] ? (
          ngo.unverifiedDonors.map((donor, index, self) => {
            if (index === self.length - 1) {
              return (
                (donors.byId[donor] && donors.byId[donor].name && (
                  <Text
                    key={index}
                    className={classes.donorName}
                    // onClick={() => history.push('/donor/' + donor)}
                  >
                    {donors.byId[donor].name}
                  </Text>
                )) ||
                []
              );
            }
            return (
              (donors.byId[donor] && donors.byId[donor].name && (
                <div key={index} style={{ marginBottom: '.35rem' }}>
                  <Text
                    className={classes.donorName}
                    // onClick={() => history.push('/donor/' + donor)}
                  >
                    {donors.byId[donor].name}
                    {', '}
                  </Text>
                </div>
              )) ||
              []
            );
          })
        ) : (
          <Text className={classes.donorName}>Aucun mécène renseigné</Text>
        ),
      valid: ngo.unverifiedDonors && donors,
    },
  ];

  const ngoInformations = [
    {
      icon: <MailOutlined className={classes.informationsIcon} />,
      description: ngo.contactMail,
      valid: ngo.contactMail,
    },
    {
      icon: <PhoneOutlined className={classes.informationsIcon} />,
      description: ngo.phoneNumber ? '+33 (0)' + ngo.phoneNumber.slice(1) : '',
      valid: ngo.phoneNumber,
    },
    {
      icon: <EnvironmentOutlined className={classes.informationsIcon} />,
      description: `${ngo.headquarterAddress}, ${ngo.headquarterZipcode} ${ngo.headquarterCity}`,
      valid:
        ngo.headquarterAddress && ngo.headquarterZipcode && ngo.headquarterCity,
    },
    {
      icon: <ClockCircleOutlined className={classes.informationsIcon} />,
      description: (
        <div style={{ display: 'flex' }}>
          <div style={{ marginBottom: 'auto', marginTop: 'auto' }}>
            {ngo.creationYear + ' (Année de création)'}
          </div>
          {renderSocials()}
        </div>
      ),
      valid: ngo.creationYear,
    },
  ];

  const displayDivision = () => {
    const odd = ngo?.sustainableDevelopmentGoals
      ? ngo?.sustainableDevelopmentGoals
      : [];
    return !!(
      ngo?.targetPopulations.length >= 1 ||
      odd.length >= 1 ||
      ngo?.activityDomains?.length >= 1 ||
      ngo.activityZone
    );
  };

  const renderReportProblem = (
    onChange: (value: string) => void,
    onSubmit: () => void,
    value: string,
  ) => (
    <div>
      <Button
        className={classes.problemButton}
        type="dashed"
        onClick={() => setVisible(true)}
        icon={<ExclamationCircleFilled style={{ color: 'green' }} />}
      >
        Signaler une anomalie avec cette fiche
      </Button>

      <Modal
        visible={visible}
        title="Signalement d'une fiche de porteur de projet"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisible(false);
              setEditedProblem('');
            }}
          >
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={() => onSubmit()}>
            Valider
          </Button>,
        ]}
      >
        <TextArea
          placeholder="Merci de préciser le sujet avec la fiche de ce porteur de projet"
          rows={4}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
      </Modal>
    </div>
  );

  return (
    <div className={classes.rightColumn}>
      {displayDivision() && (
        <div className={classes.rightColumnElement}>
          <div className={classes.problemButtonPosition}>
            {renderReportProblem(onEditProblem, onSubmitProblem, editedProblem)}
          </div>
          <List
            grid={{ gutter: 0, column: 1 }}
            dataSource={ngoCharacteristics}
            className={classes.tag}
            split={false}
            renderItem={(item) =>
              (Array.isArray(item.valid)
                ? item.valid?.length !== 0
                : !!item.valid) && (
                <List.Item>
                  <List.Item.Meta
                    title={item.title}
                    description={item.description}
                  />
                </List.Item>
              )
            }
          />
        </div>
      )}
      {ngo.personals.length !== 0 && (
        <div className={classes.rightSecondColumnElement}>
          <Text className={classes.subtitle}>
            {ngo.personals.length === 1 ? 'Membre' : 'Membres'}
          </Text>
          <Text>{ngo.personals.length === 0 ? '' : renderMembers()}</Text>
        </div>
      )}
      <div className={classes.rightColumnLastElement}>
        <List
          grid={{ gutter: 0, column: 1 }}
          dataSource={ngoInformations}
          split={false}
          renderItem={(item) =>
            item.valid && (
              <List.Item style={{ marginBottom: '4.22%' }}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      icon={item.icon}
                      className={classes.avatar}
                      shape="square"
                    />
                  }
                  description={item.description}
                />
              </List.Item>
            )
          }
        />
      </div>
      <PlacesModal
        ngoPlaces={ngo.places}
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
      />
    </div>
  );
};

export default NgoPageRightColumn;
