import { Button, Card, message, Modal, Popover, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { fetchCurrentUser } from 'src/slices/auth';
import { theme } from '../constants/theme';
import { apiRequest } from '../helpers/api';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  CallForTender,
  getCallForTenderFromDonorId,
} from '../slices/callForTender';
import { Donor, getDonor } from '../slices/donor';
import { Offer } from '../slices/financingNeed';
import { Typography } from 'antd';
import { OfferModal } from 'src/components/OfferModal';

const { Text, Title } = Typography;

const useStyles = createUseStyles({
  container: {
    backgroundImage: `url("../../images/dashboardLogo.png")`,
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    padding: '3.98% 4.59%',
    flexDirection: 'column',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  cardTitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: 'bold',
    fontSize: '1vw !important',
    lineHeight: '1 !important',
    margin: '0 0 4.76% 0 !important',
    minWidth: '12vw',
    maxWidth: '12vw',
  },
  title: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '2.3vw !important',
    lineHeight: '1 !important',
    margin: '0 0 4.76% 0 !important',
  },
  dashboardTitle: {
    fontSize: '0.98vw',
    fontWeight: 'bold',
  },
  projectContainer: {
    display: 'grid',
    columnGap: '3.372%',
    gridTemplateColumns: 'repeat(auto-fill, minmax(17.45vw, 0fr))',
  },
  card: {
    backgroundColor: '#fcfcfc',
    minWidth: '17.45vw',
    minHeight: '22vw',
    marginTop: '14.71%',
    width: '17.45vw',
    borderRadius: '1vw',
    webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    '& .ant-card-body': {
      padding: '0 5%',
      fontSize: '0.8125vw',
      ['@media screen and (max-width: 1000px)']: {
        fontSize: '8.5px',
      },
    },
  },
  headerContainer: {
    marginBottom: '4vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  subtitle: {
    fontSize: '0.98vw',
  },
  bannerImage: {
    width: '16.875vw',
    height: '7.49vw',
    borderTopRightRadius: '0.3125vw !important',
    borderTopLeftRadius: '0.3125vw !important',
  },
  button: {
    height: '4vw',
    width: '19.98%',
    backgroundColor: '#5050F6',
    borderColor: 'white',
    webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    borderRadius: '1vw',
    '&:hover': {
      backgroundColor: 'white',
      color: '#5050F6',
      border: '1px solid #5050F6',
    },
  },
  cardButton: {
    backgroundColor: '#fcfcfc',
    border: '1px solid #5050F6',
    borderRadius: '0.4vw',
    color: '#5050F6',
    '&:hover': {
      backgroundColor: '#5050F6',
      border: '1px solid #5050F6',
      color: 'white',
    },
  },
  cardButtonUnsubscribe: {
    marginTop: '0.2vw',
    backgroundColor: '#fcfcfc',
    border: '1px solid #5050F6',
    borderRadius: '0 0 0.4vw 0.4vw',
    color: '#5050F6',
    '&:hover': {
      backgroundColor: '#5050F6',
      border: '1px solid #5050F6',
      color: 'white',
    },
  },
  visibilityButton: {
    fontSize: '0.6vw',
    textAlign: 'center',
    fontWeight: 'normal',
    marginTop: '0.4vw',
    backgroundColor: '#5050F6',
    border: '1px solid #5050F6',
    borderRadius: '0 0 0.4vw 0.4vw',
    color: 'white',
    cursor: 'default',
  },
  nationaleButton: {
    marginTop: '0.4vw',
    backgroundColor: '#2dc4a8',
    border: '1px solid #2dc4a8',
    color: 'white',
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid #2dc4a8',
      color: '#2dc4a8',
    },
  },
  textButton: {
    color: '#5050F6',
    '&:hover': {
      color: 'white',
    },
  },
  cardMeta: {
    '& .ant-card-head-title': {
      whiteSpace: 'break-spaces',
      width: '100%',
    },
    '& .ant-card-head-wrapper': {
      flexDirection: 'column',
      overflow: 'hidden',
    },
    '& .ant-card-extra': {
      marginLeft: '0',
    },
    '& .ant-card-meta-description': {
      color: 'black !important',
      lineHeight: 1,
    },
    '& .ant-card-meta-title': {
      fontFamily: "'Baloo 2'",
      fontWeight: '600',
      fontSize: '1vw',
      lineHeight: 1,
      marginBottom: '0.9375vw !important',
    },
  },
  buttonDonor: {
    color: '#5050F9',
    fontWeight: 'bold',
    paddingBottom: '2%',
    '& .ant-btn': {
      width: '100%',
      fontSize: '0.6875vw',
      ['@media screen and (max-width: 1000px)']: {
        fontSize: '7.5px',
      },
      whiteSpace: 'normal',
      wordBreak: 'break-all',
      height: '100%',
      padding: '2.14% 8.02%',
    },
  },
  openProjectButton: {
    marginTop: '0.4vw',
    color: '#5050F9',
    fontWeight: 'bold',
    paddingBottom: '2%',
    '& .ant-btn': {
      width: '100%',
      fontSize: '0.6875vw',
      ['@media screen and (max-width: 1000px)']: {
        fontSize: '7.5px',
      },
      whiteSpace: 'normal',
      wordBreak: 'break-all',
      height: '100%',
      padding: '2.14% 8.02%',
    },
  },
  expirationDate: {
    color: theme.palette.primary,
    fontSize: '0.6125vw !important',
  },
  expirationDateAlt: {
    color: '#000',
    fontSize: '0.6125vw !important',
  },
  offer: {
    fontSize: '0.6125vw !important',
  },
  offerVisibility: {
    fontSize: '0.6125vw !important',
    color: 'black',
  },
  offerComplete: {
    fontSize: '0.6125vw !important',
    color: 'green',
    width: '100%',
  },
  expirationDateText: {
    fontSize: '0.6125vw !important',
  },
  switchDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
    gap: '10px',
  },
  buttonNational: {
    minHeight: '4vw',
    background:
      'linear-gradient(to right, rgba(0, 0, 255, 0.2) 0%, rgba(0, 0, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 66.66%, rgba(255, 0, 0, 0.2) 66.66%)',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    color: '#000',
    '&:hover': {
      background:
        'linear-gradient(to right, rgba(0, 0, 255, 0.3) 0%, rgba(0, 0, 255, 0.3) 33.33%, rgba(255, 255, 255, 0.3)' +
        ' 33.33%, rgba(255, 255, 255, 0.3) 66.66%, rgba(255, 0, 0, 0.3) 66.66%)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
    },
  },
  buttonNationalSettings: {
    background:
      'linear-gradient(to right, rgba(0, 0, 255, 0.2) 0%, rgba(0, 0, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 66.66%, rgba(255, 0, 0, 0.2) 66.66%)',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    color: '#000',
    '&:hover': {
      background:
        'linear-gradient(to right, rgba(0, 0, 255, 0.3) 0%, rgba(0, 0, 255, 0.3) 33.33%, rgba(255, 255, 255, 0.3)' +
        ' 33.33%, rgba(255, 255, 255, 0.3) 66.66%, rgba(255, 0, 0, 0.3) 66.66%)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
    },
    borderTop: '0px',
  },
  nationalTitle: {
    fontSize: '0.92vw',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bannerContainer: {
    marginTop: '2.5vw',
    backgroundColor: '#5050F9',
    padding: '2.5vw 2.5vw 2.5vw 2.5vw',
    borderRadius: '1vw',
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  bannerInfos: {
    display: 'flex',
    flexDirection: 'column',
  },
  bannerTitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '2vw !important',
    lineHeight: '1 !important',
    margin: '0 0 4.76% 0 !important',
    color: 'white',
  },
  bannerSubtitle: {
    fontSize: '1vw',
    color: 'white',
  },
  bannerButton: {
    height: '4vw',
    width: '19.98%',
    backgroundColor: 'white',
    borderColor: '#5050F6',
    webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    color: '#5050F6',
    fontWeight: 'bold',
    position: 'relative',
    borderRadius: '1vw',
    '&:hover': {
      backgroundColor: '#5050F6',
      color: 'white',
      border: '1px solid white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
});

interface Props {
  setIsNavBarHidden?: (isHidden: boolean) => void;
}

export const CallForTenders: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);

  const [projectIdToCancelSubscription, setProjectIdToCancelSubscription] =
    useState('');

  const [projectNameToCancelSubscription, setProjectNameToCancelSubscription] =
    useState('');

  const [showOfferModal, setShowOfferModal] = useState(false);

  const [
    expirationDateToCancelSubscription,
    setExpirationDateToCancelSubscription,
  ] = useState<Date>(new Date());

  const [projectOffer, setProjectOffer] = useState<Offer>(Offer.visibility);

  const { user, users } = useAppSelector(({ auth: { user, users } }) => ({
    user,
    users,
  }));

  const { callForTenders, donors } = useAppSelector(
    ({ callForTender: { callForTenders }, donor: { donors } }) => ({
      callForTenders,
      donors,
    }),
  );

  const [donor, setDonor] = useState<Donor | undefined>(undefined);

  useEffect(() => {
    if (setIsNavBarHidden) {
      setIsNavBarHidden(false);
    }
  }, []);

  useEffect(() => {
    dispatch(getDonor(user?.donorId));
  }, [user?.donorId]);

  useEffect(() => {
    if (user?.donorId && donors.byId[user?.donorId]) {
      setDonor(donors.byId[user?.donorId]);
    }
  }, [donors, user?.donorId]);

  useEffect(() => {
    dispatch(getCallForTenderFromDonorId(user?.donorId));
  }, []);

  useEffect(() => {
    fetchCurrentUser();
  }, [users]);

  const onClickNewProject = () => {
    if (donor?.callForTenders && donor?.callForTenders?.length + 1 > 10) {
      message.error('Vous ne pouvez pas créer plus de 10 projets.');
    } else {
      if (user?.donorId) {
        history.push(`/donor/${user?.donorId}/saveTenderDonor`);
      }
    }
  };

  const cancelSubscription = async () => {
    try {
      const response: any = await apiRequest(
        'DELETE',
        `/payment/subscriptionDatabaseAccess/${projectIdToCancelSubscription}/${projectOffer}/donor`,
        undefined,
        {},
      );
      console.log(response);
    } catch (error) {
      message.error(
        'Une erreur est survenue. Merci de rafraichir la page pour réessayer',
      );
    }
  };

  function checkOfferComplete(callForTender: CallForTender) {
    const result = callForTender.offers.find(
      (o) =>
        (o.offer == Offer.complete && !o.expirationDate) ||
        (o.offer == Offer.complete &&
          o.expirationDate &&
          new Date(o.expirationDate) > new Date()),
    )
      ? 'true'
      : 'false';
    return result;
  }

  function checkHasPaidOffer(callForTender: CallForTender) {
    const result = callForTender.offers.find(
      (o) =>
        (o.offer != Offer.visibility && !o.expirationDate) ||
        (o.offer != Offer.visibility &&
          o.expirationDate &&
          new Date(o.expirationDate) > new Date()),
    )
      ? 'true'
      : 'false';
    return result;
  }

  function isSubscriptionExpired(expirationDate: Date) {
    const todayDate = new Date();
    if (
      expirationDate.getDate === todayDate.getDate &&
      expirationDate.getMonth === todayDate.getMonth &&
      expirationDate.getFullYear === todayDate.getFullYear
    ) {
      return false;
    }
    return new Date(expirationDate) < new Date();
  }

  function renderCallForTenders() {
    return (
      <div className={classes.projectContainer}>
        {Object.values(callForTenders.byId).map((callForTender) => (
          <Card
            bordered={false}
            hoverable
            key={callForTender._id}
            className={classes.card}
            onClick={() => {
              history.push(
                `donor/${user?.donorId}/callForTender/${callForTender._id}`,
              );
            }}
          >
            <div style={{ height: '100%', padding: '2vw 0 2vw 0' }}>
              <div className={classes.cardHeader}>
                <div>
                  <Title className={classes.cardTitle}>
                    {callForTender.name}
                  </Title>
                </div>
                <div className={classes.switchDiv}>
                  <Popover
                    content={
                      <div>
                        <p>
                          Gris : les porteurs de projets ne pourront pas voir le
                          programme de soutien
                        </p>
                        <p>
                          Bleu : les porteurs de projets pourront voir le
                          programme de soutien
                        </p>
                      </div>
                    }
                    trigger="hover"
                  >
                    <Switch
                      checked={callForTender.published}
                      onClick={async (checked, event) => {
                        event.stopPropagation();
                        await apiRequest(
                          'PATCH',
                          `/call-for-tenders/visibility/${callForTender._id}`,
                          undefined,
                          {},
                        );
                        dispatch(getCallForTenderFromDonorId(user?.donorId));
                      }}
                    />
                  </Popover>
                </div>
              </div>
              <br />
              <div className={classes.openProjectButton}>
                <Button
                  style={{ padding: '0.8vw 0 0.8vw 0' }}
                  className={classes.cardButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(
                      `donor/${user?.donorId}/callForTender/${callForTender._id}`,
                    );
                  }}
                >
                  Ouvrir le programme de soutien
                </Button>
              </div>
              <div className={classes.openProjectButton}>
                <Button
                  style={{ padding: '0.8vw 0 0.8vw 0' }}
                  className={classes.cardButton}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (checkHasPaidOffer(callForTender) == 'true') {
                      history.push(`/callForTender/${callForTender._id}/ngos`);
                      return;
                    }
                    history.push(
                      `/subscription/${callForTender._id}/${checkOfferComplete(
                        callForTender,
                      )}`,
                    );
                  }}
                >
                  Tableau de suivi
                </Button>
              </div>
              <div className={classes.buttonDonor}>
                <Button
                  style={
                    checkOfferComplete(callForTender) == 'true'
                      ? {
                          padding: '0.8vw 0 0.8vw 0',
                          wordBreak: 'normal',
                          borderRadius: '0.4vw 0.4vw 0 0',
                        }
                      : {
                          padding: '0.8vw 0 0.8vw 0',
                          wordBreak: 'normal',
                          borderRadius: '0.4vw',
                        }
                  }
                  className={classes.nationaleButton}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (
                      checkOfferComplete(callForTender) == 'true' &&
                      !isSubscriptionExpired(
                        callForTender.offers.find(
                          (o) => o.offer == Offer.complete,
                        )?.expirationDate || new Date(),
                      )
                    ) {
                      history.push(
                        `/callForTenders/${callForTender._id}/database`,
                      );
                      return;
                    }
                    history.push(
                      `/subscription/${callForTender._id}/${checkOfferComplete(
                        callForTender,
                      )}`,
                    );
                  }}
                >
                  Porteurs de projet correspondants
                </Button>
                {checkOfferComplete(callForTender) == 'true' && (
                  <Button
                    type="primary"
                    className={classes.cardButtonUnsubscribe}
                    style={{ padding: '0.3vw 0 0.3vw 0' }}
                    key={
                      callForTender.offers.find(
                        (o) => o.offer === Offer.complete,
                      )?.subscriptionId
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setProjectIdToCancelSubscription(callForTender._id);
                      setProjectNameToCancelSubscription(callForTender.name);
                      setProjectOffer(
                        callForTender.offers.find(
                          (o) => o.offer === Offer.complete,
                        )?.offer || Offer.complete,
                      );
                      setExpirationDateToCancelSubscription(
                        callForTender.offers.find(
                          (o) => o.offer === Offer.complete,
                        )?.expirationDate || new Date(),
                      );
                      setShowCancelSubscriptionModal(true);
                    }}
                  >
                    <span style={{ fontSize: '0.5vw' }}>Offre Nationale</span>
                    <br />
                  </Button>
                )}
              </div>
            </div>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <div>
            <Text className={classes.title}>Bonjour {user?.firstName}</Text>
          </div>
          <div>
            <Text className={classes.subtitle}>
              Bienvenue sur votre tableau de bord
            </Text>
          </div>
        </div>
        <Button
          type="primary"
          onClick={onClickNewProject}
          className={classes.bannerButton}
          style={{ width: 'auto' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <div
              style={{
                fontSize: '0.9375vw',
                lineHeight: 1,
              }}
            >
              Ajouter un nouveau programme de soutien
            </div>
          </div>
        </Button>
      </div>
      <div>
        <div className={classes.dashboardTitle}>
          <Text>Tous mes programmes de soutien</Text>
        </div>
        <div>
          {Object.values(callForTenders.byId) &&
          Object.values(callForTenders.byId).length === 0 ? (
            <div className={classes.bannerContainer}>
              <div className={classes.bannerContent}>
                <div className={classes.bannerInfos}>
                  <div style={{ marginBottom: '1vw' }}>
                    <Text className={classes.bannerTitle}>
                      Afin de profiter au maximum de l’application Sonar, créez
                      votre premier programme de soutien.
                    </Text>
                  </div>
                  <div>
                    <Text className={classes.bannerSubtitle}>
                      N&apos;attendez plus pour accélérer vos recherches
                      d&apos;associations et
                      <br />
                      trouver les partenaires financiers idéaux pour votre
                      organisation.
                    </Text>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                  }}
                >
                  <Button
                    type="primary"
                    onClick={onClickNewProject}
                    className={classes.bannerButton}
                    style={{ width: 'auto' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '1vw',
                          lineHeight: 1,
                        }}
                      >
                        Ajouter un nouveau programme de soutien
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          ) : !Object.values(callForTenders.byId).find(
              (callForTender) => checkHasPaidOffer(callForTender) === 'true',
            ) ? (
            <>
              <div className={classes.bannerContainer}>
                <div className={classes.bannerContent}>
                  <div className={classes.bannerInfos}>
                    <div style={{ marginBottom: '1vw' }}>
                      <Text className={classes.bannerTitle}>
                        Vous désirez tirer profit au maximum de Sonar ?
                        N’hésitez plus et passez votre programme de soutien en
                        premium.
                      </Text>
                    </div>
                    <div>
                      <Text className={classes.bannerSubtitle}>
                        Découvrez les nombreux avantages de passez en premium
                        sur
                        <br />
                        Sonar.
                      </Text>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'end',
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={() => setShowOfferModal(true)}
                      className={classes.bannerButton}
                      style={{ width: 'auto' }}
                      hidden
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '1vw',
                            lineHeight: 1,
                          }}
                        >
                          Accéder aux offres Sonar
                        </div>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
              {renderCallForTenders()}
            </>
          ) : (
            <>{renderCallForTenders()}</>
          )}
        </div>
      </div>
      <Modal
        title={`Annuler mon abonnement à l'offre ${
          projectOffer === Offer.complete ? 'Nationale' : 'offre inconnue'
        } pour le programme de soutien :`}
        visible={showCancelSubscriptionModal}
        onCancel={() => setShowCancelSubscriptionModal(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setShowCancelSubscriptionModal(false)}
            type={'primary'}
          >
            Revenir en arrière
          </Button>,
          <Button
            key="submit"
            type={'primary'}
            danger
            onClick={() => {
              cancelSubscription();
              setShowCancelSubscriptionModal(false);
              alert(
                'Le renouvellement de votre abonnement a bien été annulé pour le programme de soutien : ' +
                  projectNameToCancelSubscription,
              );
            }}
          >
            Annuler mon abonnement
          </Button>,
        ]}
      >
        <Text style={{ fontSize: '24px', fontWeight: 'bold' }}>
          {projectNameToCancelSubscription}
        </Text>
        <br />
        <br />
        <br />
        <br />
        {expirationDateToCancelSubscription && (
          <Text style={{ fontSize: '18px' }}>
            Votre abonnement prendra fin le :{' '}
            {
              new Date(expirationDateToCancelSubscription)
                .toLocaleString('fr-FR')
                .split(',')[0]
            }
          </Text>
        )}
      </Modal>
      <OfferModal
        isVisible={showOfferModal}
        requestClose={() => setShowOfferModal(false)}
        type="callForTender"
        callForTenders={Object.values(callForTenders.byId).map(
          (callForTender) => callForTender,
        )}
        checkHasPaidOfferCFT={checkHasPaidOffer}
      />
    </div>
  );
};
