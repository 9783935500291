import React from 'react';
import { Modal, Tag, Typography } from 'antd';
import { createUseStyles } from 'react-jss';
import { SortedClassificationElement } from '../hooks/useSortedClassification';
import { Tag as TagType } from '../slices/classification';
import closeIcon from '../assets/icons/closeIcon.png';

const { Title } = Typography;
const useStyles = createUseStyles({
  title: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    alignSelf: 'center',
    lineHeight: '1 !important',
  },
  cell: {
    padding: '2px',
  },
  header: {
    fontWeight: 'bold',
    width: '150px',
  },
  column: {
    display: 'flex',
    flex: '25%',
    // border: '1px solid',
  },
  row: {
    display: 'flex',
    flex: '100%',
    flexShrink: 0,
    flexGrow: 0,
    flexDirection: 'row',
    padding: '10px',
  },
  tag: {
    color: 'white',
    cursor: 'pointer',
    padding: '2px 5px',
    margin: '5px 0',
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    marginRight: '1vw',
    borderRadius: '1.5vw',
    backgroundColor: '#5050f6',
    fontSize: '0.7vw',
    height: '2vw',
    border: 'none',
  },
  limitTagsText: {
    textAlign: 'right',
    margin: '8px 0',
  },
  headerButtons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '10px',
    marginBottom: '10px',
  },
});

interface MoreTagsModalProps {
  values: any;
  tags: (SortedClassificationElement & TagType)[];
  requestClose: () => void;
  isVisible: boolean;
}

export const MoreTagsModal: React.VFC<MoreTagsModalProps> = ({
  values,
  tags,
  isVisible,
  requestClose,
}) => {
  const classes = useStyles();

  return (
    <Modal
      visible={isVisible}
      closable={false}
      onCancel={() => {
        requestClose();
      }}
      onOk={() => {
        requestClose();
      }}
      width={'100%'}
      style={{
        top: '1vw',
        marginLeft: '5vw',
        borderRadius: '0.5vw',
        maxWidth: '90%',
      }}
      footer={null}
    >
      <div style={{ marginLeft: '2vw', marginBottom: '2vw' }}>
        <img
          src={closeIcon}
          alt="Close"
          style={{
            width: '1.3vw', // Adjust the width to fit your desired size
            position: 'absolute',
            top: '1.2vw',
            right: '1.5vw',
            cursor: 'pointer',
          }}
          onClick={() => {
            requestClose();
          }}
        />
        <Title className={classes.title}>
          {'Tous les mots clés sélectionnés'}
        </Title>
        {values?.map((tagId: string) => (
          <Tag key={tagId} className={classes.tag}>
            {tags.find((tag) => tag._id === tagId)?.translatedName}
          </Tag>
        ))}
      </div>
    </Modal>
  );
};
